import {
  Button,
  Popconfirm,
  Radio,
  Table,
  Tabs,
  TabsProps,
  Typography,
} from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDeleteOrgReward } from "../../../api/api.delete";
import { useGetRewardLevels, useGetRewards } from "../../../api/api.get";
import { useUpdateRewardLevel } from "../../../api/api.update";
import { useNotifications2 } from "../../../components/notifications/notification";
import OrganizationDate from "../organization-date";
import AddRewardLevelModal from "./add-reward-level-modal";
import EditRewardLevelModal from "./edit-reward-level.modal";
import ViewRewardModal from "./view-reward.modal";

const { Title } = Typography;

export default function RewardsHomePage() {
  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Transactions",
      children: <RewardsTransactionSection />,
    },
    {
      key: "2",
      label: "Coupons & Rewards",
      children: <RewardsLevelSection />,
    },
  ];

  return (
    <div style={{ marginBottom: 100 }} className="divColumn p-40 flex-1">
      <Tabs defaultActiveKey="1" items={items} onChange={() => {}} />
    </div>
  );
}

function RewardsLevelSection() {
  const { id } = useParams();
  const [isAdd, setIsAdd] = useState<boolean>(false);

  const { mutateAsync: deleteAsync } = useDeleteOrgReward(id!!);
  const [editRewardLevelId, setEditRewardLevelId] = useState<string>();
  const { mutateAsync, isPending } = useUpdateRewardLevel(id);
  const { notification, notifyError, notifySuccess } = useNotifications2();
  const [active, setActive] = useState<"true" | "false">("true");
  const { data: rewardLevels, refetch } = useGetRewardLevels(id, active);

  const handleEnableDisable = (levelId: string, newStatus: boolean) => {
    mutateAsync({
      enabled: newStatus,
      levelId,
    })
      .then(() =>
        notifySuccess({
          title: "Success",
          description: "Level successfully updated",
        }),
      )
      .catch(() => notifyError());
  };

  const rewardLevelColumns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "BarCode",
      dataIndex: "externalId",
      key: "externalId",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },

    {
      title: "Flat or Percent",
      dataIndex: "monetaryType",
      width: 150,
      key: "monetaryType",
    },
    {
      title: "value",
      dataIndex: "value",
      key: "value",
    },
    {
      title: "Min Bill Amount",
      dataIndex: "minAmount",
      key: "minAmount",
    },
    {
      title: "",
      key: "operation",
      width: 100,
      render: (row: any) => (
        <Button type="link" onClick={() => setEditRewardLevelId(row.id)}>
          Edit
        </Button>
      ),
    },
    {
      title: "",
      key: "operation",
      width: 100,
      render: (row: any) => (
        <Button
          type="link"
          loading={isPending}
          onClick={() => handleEnableDisable(row.id, !row.enabled)}
        >
          {row.enabled ? "Disable" : "Enable"}
        </Button>
      ),
    },
    {
      title: "",
      key: "operation",
      width: 100,
      render: (row: any) => (
        <Popconfirm
          title="Delete Reward"
          description="Are you sure to delete this level?"
          onConfirm={() => handleDelete(row)}
          okText="Yes"
          cancelText="No"
        >
          <Button danger type="text" color="danger">
            Delete
          </Button>
        </Popconfirm>
      ),
    },
  ];

  const handleDelete = (row: any) => {
    deleteAsync({ rewardId: row.id })
      .then(() => refetch())
      .catch(console.error);
  };

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active]);

  return (
    <div style={{ marginBottom: 100 }} className="divColumn p-0 flex-1">
      {notification}
      <AddRewardLevelModal open={isAdd} onCancel={() => setIsAdd(false)} />

      <EditRewardLevelModal
        open={!!editRewardLevelId}
        onCancel={() => setEditRewardLevelId(undefined)}
        editRewardLevelId={editRewardLevelId}
      />

      <div className="divSpread divAlignItemsOnly mt-40">
        <Title level={3}>Coupons & Rewards</Title>

        <div>
          <Radio.Group
            onChange={(e) => setActive(e.target.value)}
            value={active}
          >
            <Radio value={"true"}>Active</Radio>
            <Radio value={"false"}>InActive</Radio>
          </Radio.Group>

          <Button
            id="add-coupon-btn"
            type="primary"
            onClick={() => setIsAdd(!isAdd)}
          >
            Add Coupon
          </Button>
        </div>
      </div>

      <Table dataSource={rewardLevels} columns={rewardLevelColumns} />
    </div>
  );
}

function RewardsTransactionSection() {
  const { id } = useParams();

  const [date, setDate] = useState(moment());
  const { data: rewards, refetch } = useGetRewards(
    id,
    date.startOf("M").format("YYYY-MM-DD"),
    date.endOf("M").format("YYYY-MM-DD"),
  );

  const [viewableRewardObj, setViewableRewardObj] = useState<any>();
  const handleView = (row: any) => {
    setViewableRewardObj(row);
  };

  useEffect(() => {
    if (date) {
      refetch();
    }
  }, [date, refetch]);

  const rewardTransactionColumns = [
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Rewards",
      dataIndex: "value",
      key: "value",
    },
    {
      title: "Date",
      key: "created_at",
      render: (row: string) => <span>{moment(row).format("DD MMM YYYY")}</span>,
    },
    {
      title: "Action",
      key: "operation",
      width: 100,
      render: (row: any) => (
        <Button type="link" onClick={() => handleView(row)}>
          View
        </Button>
      ),
    },
  ];

  return (
    <div className="divColumn mt-40">
      <ViewRewardModal
        open={viewableRewardObj}
        onCancel={() => setViewableRewardObj(undefined)}
        reward={viewableRewardObj}
      />

      <div className="divSpread">
        <OrganizationDate
          onChange={(date: string) => setDate(moment(date, "MMM YYYY"))}
        />
      </div>
      <Title level={3}>Reward Transactions</Title>

      <Table dataSource={rewards} columns={rewardTransactionColumns} />
    </div>
  );
}
