import { Modal, Result } from "antd";

export function useModalNotification() {
  const [modal, contextHolder] = Modal.useModal();

  const notifySuccess = ({
    title,
    description,
  }: {
    title: string;
    description: string;
  }) => {
    modal.success({
      icon: null,
      content: <Result status="success" title={title} subTitle={description} />,
    });
  };

  const notifyError = ({
    title,
    description,
  }: {
    title?: string;
    description: string;
  }) => {
    modal.error({
      icon: null,
      content: (
        <Result
          status="error"
          title={title ?? "Whoops!"}
          subTitle={description}
        />
      ),
    });
  };

  return { notifySuccess, notification: contextHolder, notifyError };
}
