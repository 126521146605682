import { MinusCircleFilled } from "@ant-design/icons";
import {
  AutoComplete,
  AutoCompleteProps,
  Avatar,
  Button,
  Flex,
  Input,
} from "antd";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDebounce } from "use-debounce";
import { useSearchProducts } from "../api/api.get";
import { IProduct, IProductQuanityRowItem } from "../types/types";
import { getCurrencySymbol } from "../utils/functions";
import ErrorPanel from "./error.panel.component";

export function ProductQuantityRowItem({
  showPrice,
  index,
  item,
  onRemoveItem,
  onProductChange,
  onQuantityChange,
}: {
  showPrice?: boolean;
  onQuantityChange: (index: number, quantity: number) => void;
  onProductChange: (index: number, product: IProduct) => void;
  index: number;
  item: IProductQuanityRowItem;
  onRemoveItem: (key: number) => void;
}) {
  const { id } = useParams();
  const [servicesSearchText, setServicesSearchText] = useState<string>("");
  const [options, setOptions] = useState<AutoCompleteProps["options"]>([]);
  const [debouncedProductNameSearchText] = useDebounce(servicesSearchText, 300);

  const { data: productsByNameData } = useSearchProducts(
    id,
    debouncedProductNameSearchText,
  );

  useEffect(() => {
    if (productsByNameData && productsByNameData.length > 0) {
      setOptions(
        productsByNameData.map((product: IProduct) => {
          return {
            ...product,
            value: product.title,
            label: product.title,
          };
        }),
      );
    }
  }, [productsByNameData, debouncedProductNameSearchText]);

  return (
    <div key={index} className="divColumn">
      <Flex gap={10} className="divAlignItemsOnly">
        <Avatar src={item.product?.cover} />
        <AutoComplete
          allowClear
          size="large"
          status={productsByNameData?.length === 0 ? "error" : undefined}
          options={options}
          defaultValue={item.product?.title}
          onSelect={(p: any, option: any) => onProductChange(index, option)}
          onSearch={(text) => setServicesSearchText(text)}
          placeholder="Search by name..."
          notFoundContent={"No results found."}
          style={{ width: 200 }}
        />

        <Input
          size="large"
          style={{ width: 200 }}
          placeholder="Quantity"
          defaultValue={item.quantity}
          onChange={(e) => onQuantityChange(index, +e.target.value)}
        />

        {item.product && (
          <div style={{ width: 80 }}>
            <span>
              {getCurrencySymbol(item.product?.price?.currency)}{" "}
              {item.product?.price?.value}
            </span>
          </div>
        )}

        <Button
          type="text"
          size="large"
          icon={<MinusCircleFilled />}
          onClick={() => onRemoveItem(index)}
          style={{ color: "lightcoral" }}
        ></Button>
      </Flex>

      {productsByNameData?.length === 0 && (
        <ErrorPanel message="This product does not exist." />
      )}
    </div>
  );
}
