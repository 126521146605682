import { MinusCircleFilled, PlusCircleTwoTone } from "@ant-design/icons";
import {
  AutoComplete,
  AutoCompleteProps,
  Button,
  DatePicker,
  Flex,
  Input,
  Typography,
} from "antd";
import dayjs from "dayjs";
import { useFormik } from "formik";
import moment from "moment";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDebounce } from "use-debounce";
import * as Yup from "yup";
import {
  useGetSupplyChainStockRequestsById,
  useSearchProducts,
} from "../../../../api/api.get";
import { useSupplyChainUpdateStockRequest } from "../../../../api/api.update";
import ErrorPanel from "../../../../components/error.panel.component";
import { useMessage } from "../../../../components/notifications/message";
import { IProduct, IProductQuanityRowItem } from "../../../../types/types";
const { Title } = Typography;

export default function EditStockRequestPage() {
  const [stockRowItems, setStockRowItems] = useState<IProductQuanityRowItem[]>(
    [],
  );

  const { id, iid } = useParams();
  const { notification, messageError, messageSuccess } = useMessage();
  const { mutateAsync } = useSupplyChainUpdateStockRequest(id, iid);
  const { data: stockRequestData } = useGetSupplyChainStockRequestsById(
    id,
    iid,
  );

  useEffect(() => {
    if (stockRequestData?.items) {
      setStockRowItems(stockRequestData.items);
    }
  }, [stockRequestData]);

  const formik = useFormik({
    initialValues: {
      vendorName: stockRequestData?.vendorName ?? "",
      vendorEmail: stockRequestData?.vendorEmail ?? "",
      vendorPhone: stockRequestData?.vendorPhone ?? "",
      deliveryDate: stockRequestData?.deliveryDate ?? "",
      notes: stockRequestData?.notes ?? "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      vendorName: Yup.string().required("Please enter name"),
      vendorEmail: Yup.string(),
      vendorPhone: Yup.string()
        .matches(/^[0-9]+$/, "Please enter valid number")
        .required("Please enter phone"),
      deliveryDate: Yup.string().required("Please select a delivery date"),
      notes: Yup.string(),
    }),
    onSubmit: async (values) => {
      mutateAsync({
        ...values,
        items: stockRowItems,
        status: "CREATED",
      })
        .then(() => {
          formik.resetForm();
          setStockRowItems([]);
          messageSuccess("Stocks requested successfully");
        })
        .catch(() => {
          messageError(
            "Whoops! Unable to submit request. Please try after sometime.",
          );
        });
    },
  });

  const handleAddStockRowItem = () => {
    setStockRowItems([...stockRowItems, { product: null, quantity: 0 }]);
  };

  const handleRemoveItem = (index: number) => {
    const _items = [...stockRowItems];
    _items.splice(index, 1);
    setStockRowItems([..._items]);
  };

  const handleOnQuantityChange = (index: number, quantity: number) => {
    const items = [...stockRowItems];
    const item = items[index];
    const newItem = {
      ...item,
      quantity,
    };
    items.splice(index, 1, newItem);
    setStockRowItems(items);
  };

  const handleOnProductChange = (index: number, product: IProduct) => {
    const items = [...stockRowItems];
    const item = items[index];
    const newItem = {
      ...item,
      product,
    };
    items.splice(index, 1, newItem);
    setStockRowItems(items);
  };

  return (
    <div
      style={{ marginBottom: 100 }}
      className="card divColumn mx-40 my-40 flex-1"
    >
      {notification}
      <div className="divSpread divAlignItemsOnly">
        <Title level={3}>New Stock Request</Title>

        <Flex gap={10}>
          <Button
            type="primary"
            disabled={Object.keys(formik.errors).length > 0}
            onClick={formik.submitForm}
          >
            Save
          </Button>
        </Flex>
      </div>

      <form onSubmit={formik.handleSubmit}>
        <Flex gap={20} vertical className="my-40">
          <Flex gap={10} vertical className="mb-20">
            <strong>Order Date</strong>
            <span className="infoMsg">{moment().format("DD MMM YYYY")}</span>
          </Flex>

          <Flex gap={10} vertical className="mb-20">
            <strong>Vendor Name</strong>
            <div>
              <Input
                placeholder="Description"
                {...formik.getFieldProps("vendorName")}
              />
              <ErrorPanel message={formik.errors.vendorName} />
            </div>
          </Flex>

          <Flex gap={10} vertical className="mb-20">
            <strong>Vendor Email</strong>
            <Input
              placeholder="Email"
              {...formik.getFieldProps("vendorEmail")}
            />
          </Flex>

          <Flex gap={10} vertical className="mb-20">
            <strong>Vendor Phone</strong>
            <Input
              placeholder="Phone"
              {...formik.getFieldProps("vendorPhone")}
            />
            <ErrorPanel message={formik.errors.vendorPhone} />
          </Flex>

          <Flex gap={10} vertical>
            <strong>Delivery Date</strong>
            <div>
              <DatePicker
                onChange={(value) =>
                  formik.setFieldValue("deliveryDate", value)
                }
                value={
                  formik.values.deliveryDate
                    ? dayjs(formik.values.deliveryDate)
                    : null
                }
              />

              <ErrorPanel message={formik.errors.deliveryDate} />
            </div>
          </Flex>

          <Title level={4}>Products</Title>

          <Flex gap={20} vertical className="mb-40">
            <Flex gap={20} vertical>
              {stockRowItems.map(
                (item: IProductQuanityRowItem, index: number) => {
                  return (
                    <StockRowItem
                      key={index}
                      item={item}
                      index={index}
                      onRemoveItem={(key) => handleRemoveItem(key)}
                      onQuantityChange={handleOnQuantityChange}
                      onProductChange={handleOnProductChange}
                    />
                  );
                },
              )}
            </Flex>

            <Button
              icon={<PlusCircleTwoTone />}
              onClick={() => handleAddStockRowItem()}
            ></Button>
          </Flex>

          <Flex gap={10} vertical>
            <Input.TextArea
              placeholder="Notes..."
              {...formik.getFieldProps("notes")}
            />
          </Flex>
        </Flex>
      </form>
    </div>
  );
}

function StockRowItem({
  index,
  item,
  onRemoveItem,
  onProductChange,
  onQuantityChange,
}: {
  onQuantityChange: (index: number, quantity: number) => void;
  onProductChange: (index: number, product: IProduct) => void;
  index: number;
  item: IProductQuanityRowItem;
  onRemoveItem: (key: number) => void;
}) {
  const { id } = useParams();
  const [servicesSearchText, setServicesSearchText] = useState<string>("");
  const [options, setOptions] = useState<AutoCompleteProps["options"]>([]);
  const [debouncedProductNameSearchText] = useDebounce(servicesSearchText, 300);

  const { data: productsByNameData } = useSearchProducts(
    id,
    debouncedProductNameSearchText,
  );

  useEffect(() => {
    if (productsByNameData && productsByNameData.length > 0) {
      setOptions(
        productsByNameData.map((product: IProduct) => {
          return {
            ...product,
            value: product.title,
            label: product.title,
          };
        }),
      );
    }
  }, [productsByNameData, debouncedProductNameSearchText]);

  return (
    <div key={index} className="divColumn">
      <Flex gap={10}>
        <AutoComplete
          allowClear
          size="large"
          status={productsByNameData?.length === 0 ? "error" : undefined}
          options={options}
          defaultValue={item.product?.title}
          onSelect={(p: any, option: any) => onProductChange(index, option)}
          onSearch={(text) => setServicesSearchText(text)}
          placeholder="Search by name..."
          notFoundContent={"No results found."}
          style={{ width: 200 }}
        />

        <Input
          style={{ width: 200 }}
          placeholder="Quantity"
          defaultValue={item.quantity}
          onChange={(e) => onQuantityChange(index, +e.target.value)}
        />

        <Button
          icon={<MinusCircleFilled />}
          onClick={() => onRemoveItem(index)}
        ></Button>
      </Flex>

      {productsByNameData?.length === 0 && (
        <ErrorPanel message="This product does not exist." />
      )}
    </div>
  );
}
