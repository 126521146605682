import { Button, Flex, Input } from "antd";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { ICreateInvoicePayload, useCreateInvoice } from "../../../api/api";
import ErrorPanel from "../../../components/error.panel.component";
import { useNotifications } from "../../../components/notifications/notification";
import { ProductQuantityRowItem } from "../../../components/product-quantity.component";
import { useCurrency } from "../../../hooks/useCurrency";
import { IProduct, IProductQuanityRowItem } from "../../../types/types";

export default function AddInvoice() {
  const { id } = useParams();
  const { areaCode } = useCurrency();
  const { notifySuccess, notification, notifyError } = useNotifications({
    title: "Success",
    subtitle: "Invoice created successfully.",
  });

  const navigate = useNavigate();

  const { mutateAsync, isPending } = useCreateInvoice(id);
  const [invoiceItems, setInvoiceItems] = useState<IProductQuanityRowItem[]>(
    [],
  );

  const formik = useFormik({
    initialValues: {
      total: 0,
      tax: "",
      customerFirstName: "",
      customerLastName: "",
      customerStreet1: "",
      customerStreet2: "",
      customerCity: "",
      customerState: "",
      customerCountryCode: "",
      customerPhone: "",
      customerEmail: "",
      customerZipCode: "",
      products: [],
      notes: "",
    },
    validationSchema: Yup.object({
      total: Yup.number(),
      products: Yup.array().required("Please add products or services"),
      tax: Yup.string()
        .matches(/^(0|[1-9]\d*)(\.\d+)?$/, "Please enter valid number")
        .required("Please enter valid tax percentage"),
      customerFirstName: Yup.string().required("Please enter customer name"),
      customerPhone: Yup.string()
        .matches(/^(0|[1-9]\d*)(\.\d+)?$/, "Please enter valid phone number")
        .required("Please enter customer phone"),
      customerEmail: Yup.string(),
      customerZipCode: Yup.string().matches(
        /^(0|[1-9]\d*)(\.\d+)?$/,
        "Please enter valid zip code",
      ),
    }),
    onSubmit: async (values) => {
      const payload: ICreateInvoicePayload = {
        items: invoiceItems,
        tax: +values.tax,
        total: values.total,
        notes: values.notes,
        customer: {
          firstName: values.customerFirstName,
          lastName: values.customerLastName,
          email: values.customerEmail,
          phone: values.customerPhone,
          address: {
            street1: values.customerStreet1,
            street2: values.customerStreet2,
            city: values.customerCity,
            state: values.customerState,
            countryCode: values.customerCountryCode,
            zipCode: values.customerZipCode,
          },
        },
      };

      mutateAsync(payload)
        .then(() => {
          formik.resetForm();
          setInvoiceItems([]);
          notifySuccess();
        })
        .catch(() => {
          notifyError();
        });
    },
  });

  const handleAddStockRowItem = () => {
    setInvoiceItems([...invoiceItems, { product: null, quantity: 0 }]);
  };

  const handleRemoveItem = (index: number) => {
    const _items = [...invoiceItems];
    _items.splice(index, 1);
    setInvoiceItems([..._items]);
  };

  const handleOnQuantityChange = (index: number, quantity: number) => {
    const items = [...invoiceItems];
    const item = items[index];
    const newItem = {
      ...item,
      quantity,
    };
    items.splice(index, 1, newItem);
    setInvoiceItems(items);
  };

  const handleOnProductChange = (index: number, product: IProduct) => {
    const items = [...invoiceItems];
    const item = items[index];
    const newItem = {
      ...item,
      product,
    };
    items.splice(index, 1, newItem);
    setInvoiceItems(items);
  };

  useEffect(() => {
    let itemTotal = invoiceItems.reduce(
      (acc, item) => acc + (item.product?.price?.value ?? 0) * item.quantity,
      0,
    );
    if (+formik.values.tax > 0) {
      itemTotal += (+formik.values.tax / 100) * itemTotal;
    }
    formik.setFieldValue("total", itemTotal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceItems, formik.values.tax]);

  return (
    <div
      style={{ backgroundColor: "var(--exhut-light-grey5)" }}
      className="divColumn p-40 flex-1"
    >
      <div
        className="card p-40"
        style={{ marginBottom: 100, backgroundColor: "var(--exhut-white)" }}
      >
        <div className="divSpread divAlignItemsOnly">
          <h1>Invoice</h1>

          <div className="">
            <Button
              type="link"
              onClick={() => navigate(`/organizations/${id}/invoices`)}
            >
              Back
            </Button>

            <Button
              onClick={formik.submitForm}
              loading={isPending}
              type="primary"
            >
              Create
            </Button>
          </div>
        </div>

        <Flex gap={20} vertical className="my-40">
          <h3>Items</h3>
          <Flex gap={20} vertical>
            {invoiceItems.map((item: IProductQuanityRowItem, index: number) => {
              return (
                <ProductQuantityRowItem
                  key={index}
                  item={item}
                  index={index}
                  onRemoveItem={(key) => handleRemoveItem(key)}
                  onQuantityChange={handleOnQuantityChange}
                  onProductChange={handleOnProductChange}
                />
              );
            })}
          </Flex>
          <div className="my-0">
            <Button onClick={() => handleAddStockRowItem()} type="link">
              Add Item
            </Button>
          </div>
        </Flex>

        <form onSubmit={formik.handleSubmit}>
          {notification}
          <Flex gap={30} vertical className="mt-40">
            <div>
              <h3>Tax</h3>
              <Input
                size="middle"
                prefix={"%"}
                placeholder="Taxes"
                {...formik.getFieldProps("tax")}
              />
              <ErrorPanel message={formik.errors.tax} />
            </div>

            <div className="mt-40">
              <h3>Total</h3>
              <h1> ${formik.values.total.toFixed(2)}</h1>
            </div>

            <div>
              <h4 className="mb-40">Customer Details</h4>

              <Flex gap={50}>
                <Flex gap={30} vertical>
                  <Flex gap={10} vertical>
                    <span>First Name</span>
                    <Input
                      status={
                        !!formik.errors.customerFirstName ? "error" : undefined
                      }
                      {...formik.getFieldProps("customerFirstName")}
                    />
                  </Flex>

                  <Flex gap={10} vertical>
                    <span>Last Name</span>
                    <Input {...formik.getFieldProps("customerLastName")} />
                  </Flex>
                  <Flex gap={10} vertical>
                    <span>Email</span>
                    <Input {...formik.getFieldProps("customerEmail")} />
                  </Flex>

                  <Flex gap={10} vertical>
                    <span>Phone</span>
                    <Input
                      prefix={areaCode}
                      maxLength={10}
                      status={
                        !!formik.errors.customerPhone ? "error" : undefined
                      }
                      {...formik.getFieldProps("customerPhone")}
                    />
                  </Flex>
                </Flex>

                <Flex gap={30} vertical>
                  <Flex gap={10} vertical>
                    <span>Street 1</span>
                    <Input {...formik.getFieldProps("customerStreet1")} />
                  </Flex>

                  <Flex gap={10} vertical>
                    <span>Street 2</span>
                    <Input {...formik.getFieldProps("customerStreet2")} />
                  </Flex>
                  <Flex gap={10} vertical>
                    <span>City</span>
                    <Input {...formik.getFieldProps("customerCity")} />
                  </Flex>

                  <Flex gap={10} vertical>
                    <span>State</span>
                    <Input {...formik.getFieldProps("customerState")} />
                  </Flex>
                </Flex>

                <Flex gap={30} vertical>
                  <Flex gap={10} vertical>
                    <span>Country</span>
                    <Input {...formik.getFieldProps("customerCountryCode")} />
                  </Flex>

                  <Flex gap={10} vertical>
                    <span>Zip Code</span>
                    <Input {...formik.getFieldProps("customerZipCode")} />
                    <ErrorPanel message={formik.errors.customerZipCode} />
                  </Flex>
                </Flex>
              </Flex>
            </div>
          </Flex>

          <div>
            <h4>Notes</h4>

            <Input.TextArea
              placeholder="Notes"
              {...formik.getFieldProps("notes")}
            />
          </div>
        </form>
      </div>
    </div>
  );
}
