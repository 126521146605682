import { Button, Modal, Table } from "antd";
import { useRef } from "react";
import Barcode from "react-barcode";
import { useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import {
  useGetOrganizationById,
  useGetOrganizationSalesById,
} from "../../../api/api.get";
import { useCurrency } from "../../../hooks/useCurrency";
import {
  IOrganization,
  IOrganizationSale,
  IProduct,
} from "../../../types/types";
import { toReadableDate } from "../../../utils/date.utils";
import { capitalize } from "../../../utils/functions";

export default function ReceiptModal({
  open,
  onCancel,
  sales,
}: {
  sales?: IOrganizationSale;
  open: boolean;
  onCancel: () => void;
}) {
  const contentRef = useRef(null);
  const handlePrint = useReactToPrint({
    contentRef,
  });
  const { id } = useParams();
  const { toAmount } = useCurrency();

  const { data: saleData } = useGetOrganizationSalesById(id, sales?.id);
  const { data: orgData } = useGetOrganizationById(id);

  const columns = [
    {
      title: "Item",
      dataIndex: "item",
      key: "item",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      width: 200,
    },
  ];

  const dataSource = saleData?.products.map(
    (product: IProduct, index: number) => {
      return {
        key: index,
        item: product.title,
        price: `${product.price?.currency} ${product.price?.value}`,
      };
    },
  );

  return (
    <Modal
      title="Receipt"
      open={open}
      onOk={onCancel}
      onCancel={onCancel}
      okText="OK"
    >
      <div className="divRight">
        <Button onClick={() => handlePrint()} type="link">
          Print
        </Button>
      </div>

      <div className="my-0 mb-20 p-40" ref={contentRef}>
        <OrganizationDetailsSection org={orgData} />

        <div className="divRight my-20">
          <strong>{toReadableDate(saleData?.created_at)}</strong>
        </div>

        <div className="mt-60">
          <Table
            dataSource={dataSource}
            columns={columns}
            pagination={{ hideOnSinglePage: true }}
          />
        </div>

        <div>
          <div className="divSpread my-10">
            <h2>Total</h2>
            <h2>{toAmount(saleData?.total)}</h2>
          </div>

          <div className="divSpread my-10">
            <strong>Items Total</strong>
            <strong>{toAmount(saleData?.amount)}</strong>
          </div>
          <div className="divSpread my-10">
            <strong>Discount</strong>
            <strong>{toAmount(saleData?.discountTotal)}</strong>
          </div>
          <div className="divSpread my-0">
            <strong>Tax</strong>
            <strong>{saleData?.tax}%</strong>
          </div>

          <div className="divSpread my-0">
            <strong>Payment</strong>
            <strong>
              {saleData?.payments?.[0]?.paymentType.toUpperCase()}
            </strong>
          </div>

          <div className="divSpread my-0">
            <strong>Customer Phone</strong>
            <strong>{saleData?.payments?.[0]?.customerPhone}</strong>
          </div>
        </div>

        <div className="divCenterAlign my-60">
          <Barcode value={saleData?.externalId ?? ""} />
        </div>
      </div>
    </Modal>
  );
}

function OrganizationDetailsSection({ org }: { org?: IOrganization }) {
  return (
    <div className="divCenterAlign divColumn">
      <h2>{capitalize(org?.name)}</h2>
      <div className="divColumn">
        <span>{capitalize(org?.street1)}</span>
        <span>{capitalize(org?.street2)}</span>
        <span>{capitalize(org?.city)}</span>
        <span>
          {capitalize(org?.state)}, <strong>{org?.country}</strong>{" "}
          {org?.zipCode}
        </span>
      </div>
    </div>
  );
}
