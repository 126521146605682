import { DeleteOutlined } from "@ant-design/icons";
import {
  Avatar,
  Button,
  Card,
  Divider,
  Flex,
  Input,
  List,
  Modal,
  Row,
  Skeleton,
  Tag,
} from "antd";
import Meta from "antd/es/card/Meta";
import Select from "antd/es/select";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useDebounce } from "use-debounce";
import { useCreateOrgSale } from "../../../../api/api";
import { useDeleteRestaurantTable } from "../../../../api/api.delete";
import {
  useGetOrganizationById,
  useGetRestaurantTableById,
  useSearchAllProducts,
  useSearchProducts,
} from "../../../../api/api.get";
import { useUpdateRestaurantTable } from "../../../../api/api.update";
import { useMessage } from "../../../../components/notifications/message";
import { useNotifications2 } from "../../../../components/notifications/notification";
import { IProduct } from "../../../../types/types";

export default function RestaurantTabPage() {
  const { id, tid } = useParams();
  const [searchText, setSearchText] = useState<string>("");
  const navigate = useNavigate();

  const [items, setItems] = useState<IProduct[]>([]);

  const { data: tableData } = useGetRestaurantTableById(id, tid);

  const [productsToCheckout, setProductsToCheckout] = useState<IProduct[]>([]);

  const { data: orgData } = useGetOrganizationById(id);
  const [debouncedProductNameSearchText] = useDebounce(searchText, 300);
  const { notification, notifyError, notifySuccess } = useNotifications2();
  const { notification: messageNotifcation, messageSuccess } = useMessage();
  const [confirmCheckout, setConfirmCheckout] = useState<boolean>(false);
  const { mutateAsync, isPending } = useCreateOrgSale(id);
  const { mutateAsync: updateTableAsync, isPending: isUpdateTablePending } =
    useUpdateRestaurantTable(id, tid);

  const { mutateAsync: deleteTableAsync } = useDeleteRestaurantTable(id!!);

  const [searchParams] = useSearchParams();

  const isDebug = searchParams.get("debug") === "true";

  const { data: productsByNameData, isLoading } = useSearchProducts(
    id,
    debouncedProductNameSearchText,
  );

  const { data: allProducts, isLoading: allProductsLoading } =
    useSearchAllProducts(id);

  useEffect(() => {
    if (tableData?.products) {
      setProductsToCheckout(tableData.products);
    }
  }, [tableData]);

  useEffect(() => {
    if (debouncedProductNameSearchText && productsByNameData) {
      setItems(productsByNameData);
    } else {
      setItems(allProducts ?? []);
    }
  }, [debouncedProductNameSearchText, allProducts, productsByNameData]);

  const handleRemoveItems = (index: number) => {
    const _items = [...productsToCheckout];
    _items.splice(index, 1);
    setProductsToCheckout(_items);
  };

  const itemTotal: number = productsToCheckout.reduce(
    (acc, item) => acc + (item.price?.value ?? 0),
    0,
  );

  /**
   * This is a super critical function
   *
   */
  const grandTotal = useMemo(() => {
    // items can have sales tax excluded
    const orgSalesTax = orgData?.salesTax ?? 0;

    return productsToCheckout.reduce((acc, item) => {
      const itemPrice = item.price?.value ?? 0;

      if (item.salesTaxExcluded === true) {
        return acc + itemPrice;
      }
      return acc + itemPrice + (itemPrice * orgSalesTax) / 100;
    }, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemTotal]);

  const handleAddItems = (item: IProduct) => {
    const existingProductsToCheckout = [...productsToCheckout];
    existingProductsToCheckout.push(item);
    setProductsToCheckout(existingProductsToCheckout);
  };

  const handleCheckout = (paymentType: string, phone?: string) => {
    setConfirmCheckout(false);
    mutateAsync({
      amount: itemTotal,
      saleDate: moment().format("YYYY-MM-DD"),
      category: items?.[0].title ?? "Scan Order - unknown",
      products: items.map((item) => item._id),
      tax: orgData?.salesTax ?? 0,
      phone,
      paymentType,
      type: "restaurant",
    })
      .then(() => {
        deleteTableAsync({ tableId: tid })
          .then(() => {
            notifySuccess({
              title: "Success",
              description: "Order successfully processed!",
            });
            setConfirmCheckout(false);
            setProductsToCheckout([]);
          })
          .catch(() => {
            notifyError("Whoops! Unable to clear table.");
          });
      })
      .catch(() => notifyError());
  };

  const handleSaveTable = () => {
    updateTableAsync({
      products: productsToCheckout,
    })
      .then(() => {
        messageSuccess("Table Saved");
      })
      .catch(() => notifyError());
  };

  return (
    <div className="w-100 divFlex p-20" style={{ height: "100%" }}>
      {notification}
      {messageNotifcation}

      <ConfirmCheckout
        total={grandTotal}
        open={confirmCheckout}
        onOK={(paymentType) => handleCheckout(paymentType!!)}
        onCancel={() => setConfirmCheckout(false)}
      />
      <div className="flex-1 p-0 divSpread divColumn">
        <div className="mx-20">
          <div className="divSpread mb-20 mx-0">
            <Button
              type="link"
              onClick={() => navigate(`/organizations/${id}/sales/restaurants`)}
            >
              {" "}
              All Tables
            </Button>
            <Input
              onChange={(e) => setSearchText(e.target.value)}
              value={searchText}
              allowClear
              style={{ width: "50%" }}
            />
          </div>

          <div style={{ overflowY: "scroll", height: 600 }}>
            <Row gutter={16}>
              <Skeleton loading={isLoading || allProductsLoading}>
                {items.map((item: IProduct, index: number) => {
                  return (
                    <Card
                      key={index}
                      onClick={() => handleAddItems(item)}
                      hoverable
                      style={{
                        width: 200,
                        marginLeft: 10,
                        marginRight: 10,
                        padding: 10,
                        marginTop: 10,
                        marginBottom: 10,
                      }}
                      cover={<img alt="example" src={item.cover} />}
                    >
                      <Meta title={item.title} />
                    </Card>
                  );
                })}
              </Skeleton>
            </Row>
          </div>
        </div>

        <div className="p-20 divColumn"></div>
      </div>

      <div className="flex-1 divColumn divSpread">
        <div>
          <div className="mb-20 divSpread">
            <Button
              type="primary"
              onClick={handleSaveTable}
              loading={isUpdateTablePending}
            >
              Save
            </Button>

            <Flex gap={5}>
              <div className="divAlignItemsOnly">
                <Tag color="green">Table {tableData?.value}</Tag>
              </div>
            </Flex>

            <Button
              type="primary"
              onClick={() => setConfirmCheckout(true)}
              disabled={productsToCheckout.length === 0}
              loading={isPending}
            >
              Checkout
            </Button>
          </div>

          <Flex gap={5} className="divColumn">
            <List
              itemLayout="horizontal"
              bordered
              dataSource={productsToCheckout}
              renderItem={(item, index) => (
                <List.Item>
                  <List.Item.Meta
                    avatar={<Avatar src={item.cover} />}
                    title={<a href="https://ant.design">{item.title}</a>}
                    description={"$" + item.price?.value}
                  />

                  <Flex gap={20}>
                    <DeleteOutlined
                      style={{ color: "red" }}
                      onClick={() => handleRemoveItems(index)}
                    />
                  </Flex>
                </List.Item>
              )}
            />
          </Flex>
        </div>

        <Divider />

        <div
          className="w-100 divColumn right-align"
          style={{ marginRight: 20, marginBottom: 0 }}
        >
          <span className="my-20" style={{ fontSize: 20 }}>
            Total : <strong>{itemTotal}</strong>
          </span>

          <span className="my-20" style={{ fontSize: 20 }}>
            Tax: <strong>{orgData?.salesTax ?? 0}%</strong>
            {isDebug && (
              <span>[{((orgData?.salesTax ?? 0) / 100) * itemTotal}]</span>
            )}
          </span>
          <span style={{ fontSize: 65, fontWeight: "bold" }}>
            ${grandTotal.toFixed(2)}
          </span>
        </div>
      </div>
    </div>
  );
}

function ConfirmCheckout({
  open,
  total,
  onCancel,
  onOK,
}: {
  total: number;
  open: boolean;
  onOK: (paymentType?: string, phone?: string) => void;
  onCancel: () => void;
}) {
  const [paymentType, setPaymentType] = useState<string>();
  const [phone, setPhone] = useState<string>();
  return (
    <div>
      <Modal
        title="Confirm Order?"
        open={open}
        onOk={() => onOK(paymentType, phone)}
        onCancel={onCancel}
      >
        <div className="divColumn">
          <Flex className="divFlex divAlignItemsOnly my-20" gap={10}>
            <strong>Total</strong>
            <h1>${total.toFixed(2)}</h1>
          </Flex>

          <Flex vertical gap={10} className="my-40">
            <Flex vertical gap={10}>
              <span>Select Payment Type</span>
              <Select
                id="first-time-select"
                defaultValue=""
                onChange={(value) => setPaymentType(value)}
                options={[
                  { value: "cash", label: "Cash" },
                  {
                    value: "card",
                    label: "Credit Card",
                  },
                ]}
              />
            </Flex>

            <Flex vertical gap={10}>
              <span>Customer Phone</span>
              <Input value={phone} onChange={(e) => setPhone(e.target.value)} />
            </Flex>
          </Flex>
        </div>
      </Modal>
    </div>
  );
}
