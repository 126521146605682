import { Button, Divider, Row, Typography } from "antd";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  useGetOrganizationById,
  useGetOrganizationEmployeesSalaryTotal,
  useGetOrganizationExpensesTotal,
  useGetOrganizationInvestmentsTotal,
  useGetOrganizationSalesTotal,
  useGetReimbursements,
  useGetRewards,
} from "../../../api/api.get";
import { getCurrencySymbol } from "../../../utils/functions";
import OrganizationDate from "../organization-date";
import AddRedeemModal from "../rewards/add-redeem.modal";
import AddRewardModal from "../rewards/add-reward.modal";
import ExpensesChartComponent from "./expenses.chart.component";
import SalesChartComponent from "./sales.chart.component";

export default function BusinessOwnerSection() {
  const { id } = useParams();
  const [date, setDate] = useState(moment());

  const { data: orgData } = useGetOrganizationById(id);

  const currency = useMemo(
    () => getCurrencySymbol(orgData?.country),
    [orgData],
  );

  const [addRewardMode, setAddRewardMode] = useState<boolean>(false);
  const [addRedeemMode, setAddRedeemMode] = useState<boolean>(false);

  const { data: expensesTotal, refetch } = useGetOrganizationExpensesTotal(
    id,
    date.startOf("month").format("YYYY-MM-DD"),
    date.endOf("month").format("YYYY-MM-DD"),
  );

  const { data: reimbursements, refetch: refetchReimbursements } =
    useGetReimbursements(
      id,
      date.startOf("month").format("YYYY-MM-DD"),
      date.endOf("month").format("YYYY-MM-DD"),
    );

  const { data: salaryTotal, refetch: refetchSalaryTotal } =
    useGetOrganizationEmployeesSalaryTotal(
      id,
      date.startOf("month").format("YYYY-MM-DD"),
      date.endOf("month").format("YYYY-MM-DD"),
    );

  const { data: rewards, refetch: refetchRewards } = useGetRewards(
    id,
    date.startOf("month").format("YYYY-MM-DD"),
    date.endOf("month").format("YYYY-MM-DD"),
  );

  // investments total are calculated for investments that were created from that month.
  // For example if investment is created Sept 20th, then we show in Sept, Oct and forward but not to Aug
  const { data: investmentsTotal, refetch: refetchInvestments } =
    useGetOrganizationInvestmentsTotal(
      id,
      date.endOf("month").format("YYYY-MM-DD"),
    );

  const { data: salesTotal, refetch: refetchSales } =
    useGetOrganizationSalesTotal(
      id,
      date.startOf("month").format("YYYY-MM-DD"),
      date.endOf("month").format("YYYY-MM-DD"),
    );

  useEffect(() => {
    if (date) {
      refetch();
      refetchSales();
      refetchInvestments();
      refetchSalaryTotal();
      refetchReimbursements();
      refetchRewards();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  const profit = (
    (salesTotal?.total ?? 0) +
    (investmentsTotal?.total ?? 0) -
    ((salaryTotal?.total ?? 0) + (expensesTotal?.total ?? 0))
  ).toFixed(2);

  return (
    <div className="mb-80 divCenterAlign divColumn">
      <AddRewardModal
        open={addRewardMode}
        onCancel={() => setAddRewardMode(false)}
        countryCode={orgData?.country}
      />

      <AddRedeemModal
        open={addRedeemMode}
        onCancel={() => setAddRedeemMode(false)}
        currency={currency}
        countryCode={orgData?.country}
      />

      <div className="w-100 divSpread">
        <OrganizationDate onChange={(d) => setDate(moment(d, "MMM YYYY"))} />

        <div>
          <Button onClick={() => setAddRedeemMode(!addRedeemMode)} type="link">
            Quick Redeem
          </Button>

          <Button onClick={() => setAddRewardMode(!addRewardMode)} type="link">
            Quick Reward
          </Button>
        </div>
      </div>

      <Row gutter={[20, 20]} className="mt-40">
        <div className="card mb-20 p-40">
          <h4>Net Profit</h4>
          <div>
            <h1 id="net-proceeds-value">
              {currency}
              {profit ?? 0}
            </h1>
          </div>
        </div>

        <ExhutCard
          value={salesTotal?.total}
          currency={currency}
          title={"Sales"}
          link={`/organizations/${id}/sales`}
        />

        <ExhutCard
          value={expensesTotal?.total}
          currency={currency}
          title={"Expenses"}
          link={`/organizations/${id}/expenses`}
        />

        <ExhutCard
          value={investmentsTotal?.total}
          currency={currency}
          title={"Investments"}
          link={`/organizations/${id}/investments`}
        />

        <ExhutCard
          value={salaryTotal?.total}
          currency={currency}
          title={"Employees Salary"}
          link={`/organizations/${id}/employees`}
        />

        <ExhutCard
          value={rewards?.length}
          title={"Rewards"}
          type="none"
          link={`/organizations/${id}/rewards`}
        />

        <ExhutCard
          value={reimbursements?.length}
          type="none"
          title={"Reimbursements"}
          link={`/organizations/${id}/reimbursements`}
        />
      </Row>

      <Divider style={{ marginTop: 120, backgroundColor: "bisque" }} />

      <div
        id="sales-charts-container"
        className="my-40"
        style={{ marginTop: 0, height: 400, width: "100%" }}
      >
        <div className="my-40">
          <Typography.Title level={3}>Sales</Typography.Title>
        </div>
        <SalesChartComponent />
      </div>

      <Divider style={{ marginTop: 200, backgroundColor: "bisque" }} />

      <div
        id="expenses-charts-container"
        className="my-0"
        style={{ marginTop: 0, height: 400, width: "100%" }}
      >
        <div className="my-40">
          <Typography.Title level={3}>Expenses</Typography.Title>
        </div>
        <ExpensesChartComponent />
      </div>
    </div>
  );
}

interface IProps {
  value?: number;
  currency?: string;
  id?: string;
  title: string;
  link?: string;
  type?: "currency" | "none";
}

export function ExhutCard({
  value,
  currency,
  id,
  title,
  link,
  type = "currency",
}: IProps) {
  const _value = useMemo(() => {
    if (type === "currency") {
      return value?.toFixed(2) ?? 0.0;
    }
    if (type === "none") {
      return value;
    }
    return "-";
  }, [value, type]);
  return (
    <div className="card">
      <span className="infoMsg divCenterAlign">{title}</span>
      <div className="mt-20 divCenterAlign">
        <span style={{ fontSize: "xx-large" }} id={id}>
          {(currency ?? "") + _value}
        </span>
      </div>
      {link && (
        <div className="mt-20 divCenterAlign">
          <Link to={link}>View</Link>
        </div>
      )}
    </div>
  );
}
