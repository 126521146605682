import { Button, Popconfirm, Table, Typography } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDeleteOrgExpense } from "../../../api/api.delete";
import { useGetOrganizationExpenses } from "../../../api/api.get";
import { IOrganizationExpense } from "../../../types/types";
import OrganizationDate from "../organization-date";
import AddExpenseModal from "./add-expense-modal";
import ViewExpenseModal from "./view-expense-modal";

const { Title } = Typography;

export default function OrganizationExpensesHomePage() {
  const { id } = useParams();
  const [date, setDate] = useState(moment());

  const { data: expenses, refetch } = useGetOrganizationExpenses(
    id,
    date.startOf("month").format("YYYY-MM-DD"),
    date.endOf("month").format("YYYY-MM-DD"),
  );
  const [viewableExpenseObj, setViewableExpenseObj] =
    useState<IOrganizationExpense>();

  const { mutateAsync: deleteAsync } = useDeleteOrgExpense(id!!);
  const [isAdd, setIsAdd] = useState<boolean>(false);

  const handleView = (row: any) => {
    setViewableExpenseObj(row);
  };

  const handleDateChange = (dateString: string) => {
    setDate(moment(dateString, "MMM YYYY"));
  };

  useEffect(() => {
    if (date) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  const handleDelete = (row: any) => {
    deleteAsync({ expenseId: row.id })
      .then(() => refetch())
      .catch(console.error);
  };

  const columns = [
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Date",
      dataIndex: "expenseDate",
      key: "expenseDate",
      render: (value: string) => (
        <span>{moment(value).format("DD MMM YYYY")}</span>
      ),
    },
    {
      title: "",
      key: "operationView",
      width: 100,
      render: (row: any) => (
        <Button type="link" onClick={() => handleView(row)}>
          Edit
        </Button>
      ),
    },
    {
      title: "",
      key: "operation",
      width: 100,
      render: (row: any) => (
        <Popconfirm
          title="Delete"
          description="Are you sure to delete this investment?"
          onConfirm={() => handleDelete(row)}
          okText="Yes"
          cancelText="No"
        >
          <Button danger type="text">
            Delete
          </Button>
        </Popconfirm>
      ),
    },
  ];

  return (
    <div
      style={{ marginBottom: 100 }}
      className="card divColumn mx-40 my-40 flex-1"
    >
      <AddExpenseModal open={isAdd} onCancel={() => setIsAdd(false)} />
      <ViewExpenseModal
        open={!!viewableExpenseObj}
        onCancel={() => setViewableExpenseObj(undefined)}
        expense={viewableExpenseObj}
      />
      <div className="divSpread divAlignItemsOnly">
        <OrganizationDate onChange={handleDateChange} />
        <Button type="primary" onClick={() => setIsAdd(!isAdd)}>
          Add
        </Button>
      </div>
      <div className="divSpread divAlignItemsOnly mt-40">
        <Title level={3}>Expenses</Title>
      </div>
      <Table dataSource={expenses} columns={columns} />
    </div>
  );
}
