import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { IProduct, IProfile, IProfileSettings } from "../types/types";
import { EXHUT_BIZ_LOCAL_STORAGE_TOKEN } from "../utils/constants";
import {
  ICreateInvoicePayload,
  ICreateOrgExpensePayload,
  ICreateOrgInvestmentPayload,
  ICreateOrgPayload,
  ICreateProductPayload,
  ICreateStockRequestPayload,
} from "./api";

export function useSupplyChainUpdateStockRequest(
  id?: string,
  stockReqId?: string,
) {
  return _usePatch<any, any, ICreateStockRequestPayload>({
    path: `organizations/${id}/supplychain/stock-requests/${stockReqId}`,
    keys: ["supplychain", "stock-requests", stockReqId!!],
    resetKeys: [],
  });
}

export interface IUpdateRestaurantTablePayload {
  notes?: string;
  value?: string;
  products: IProduct[];
}

export function useUpdateRestaurantTable(id?: string, tid?: string) {
  return _usePatch<any, any, IUpdateRestaurantTablePayload>({
    path: `organizations/${id}/restaurant-tabs/${tid}`,
    keys: ["organizations", id!!, "restaurant-tabs", tid!!],
    resetKeys: [],
  });
}

export interface IUpdateSalePayload {
  notes?: string;
}

export function useUpdateSale(id?: string, sid?: string) {
  return _usePatch<any, any, IUpdateSalePayload>({
    path: `organizations/${id}/sales/${sid}`,
    keys: ["organizations", id!!, "sales", sid!!],
    resetKeys: [],
  });
}

interface IUpdateRewardLevelPayload {
  name?: string;
  type?: string;
  value?: number;
  monetaryType?: string;
  minAmount?: number;
  enabled?: boolean;
  levelId?: string;
}

export function useUpdateRewardLevel(orgId?: string) {
  return _usePatch<any, any, IUpdateRewardLevelPayload>({
    path: `organizations/${orgId}/rewards-level/:levelId`,
    keys: ["organizations", orgId!!, "rewards-level"],
    resetKeys: [],
    replacePathParams: "levelId",
  });
}

export function useUpdateInvestment(id?: string, investmentId?: string) {
  return _usePatch<any, any, ICreateOrgInvestmentPayload>({
    path: `organizations/${id}/investments/${investmentId}`,
    keys: ["organizations", id!!, "investments", investmentId!!],
    resetKeys: [],
  });
}

export function useUpdateExpense(id?: string, eid?: string) {
  return _usePatch<any, any, ICreateOrgExpensePayload>({
    path: `organizations/${id}/expenses/${eid}`,
    keys: ["organizations", id!!, "expenses", eid!!],
    resetKeys: [],
  });
}

type IUpdateOrgPayload = Partial<ICreateOrgPayload> & {
  salesTax?: number;
  incomeTax?: number;
  logo?: string;
};

export function useUpdateOrganization(id?: string) {
  return _usePatch<any, any, IUpdateOrgPayload>({
    path: `organizations/${id}`,
    keys: ["organizations", id!!],
    resetKeys: [],
  });
}

export type IUpdateProfile = Omit<IProfile, "email" | "entitlement">;

export function useUpdateProfile() {
  return _usePut<any, any, IUpdateProfile>({
    path: `users/profile`,
    keys: ["profile"],
  });
}

interface IUpdateSettings {
  entitlement?: number;
  status?: boolean;
}
interface IUpdateSettingsPayload {
  username: string;
  settings: IUpdateSettings;
}

export function useUpdateSettings() {
  return _usePut<any, any, IUpdateSettingsPayload>({
    path: `admin/users/settings`,
    keys: ["profile"],
  });
}

function _usePut<T1, T2, T3>({
  path,
  keys,
  resetKeys,
  host,
}: {
  path: string;
  keys?: string[];
  resetKeys?: string[];
  host?: string;
}) {
  const _host = host ?? process.env.REACT_APP_BE_URL;
  const _url = `${_host}/${path}`;

  let headers: any = {
    "Content-Type": "application/json",
  };
  headers["Authorization"] = localStorage.getItem(
    EXHUT_BIZ_LOCAL_STORAGE_TOKEN,
  );

  const queryClient = useQueryClient();

  return useMutation<T1, T2, T3>({
    mutationFn: async (req) => {
      return axios
        .put(_url, req, {
          headers,
        })
        .then((resp) => resp.data);
    },
    mutationKey: keys,
    onSuccess: () => queryClient.invalidateQueries({ queryKey: resetKeys }),
  });
}

interface IUpdateEmployee {
  salary?: number;
  settings?: IProfileSettings;
  employmentEndDate?: string;
}

export function useUpdateEmployee(orgId?: string, empId?: string) {
  return _usePatch<any, any, IUpdateEmployee>({
    path: `organizations/${orgId}/employees/${empId}`,
    keys: ["organizations", orgId!!, "employees", empId!!],
  });
}

export type IUpdateInvoicePayload = Partial<ICreateInvoicePayload>;

export function updateUpdateInvoice(orgId?: string, invoiceId?: string) {
  return _usePatch<any, any, IUpdateInvoicePayload>({
    path: `organizations/${orgId}/invoices/${invoiceId}`,
    keys: ["organizations", orgId!!, "invoices", invoiceId!!],
  });
}

export type IUpdateProductPayload = Partial<ICreateProductPayload>;

export function useUpdateProduct(orgId?: string, productId?: string) {
  return _usePatch<any, any, IUpdateProductPayload>({
    path: `organizations/${orgId}/products/${productId}`,
    keys: ["products", productId!!],
    host: process.env.REACT_APP_PRODUCTS_URL,
  });
}

export interface IUpdateProductCoverPayload {
  imageId: string;
}

export function useUpdateProductCover(orgId?: string, productId?: string) {
  return _usePatch<any, any, IUpdateProductCoverPayload>({
    path: `organizations/${orgId}/products/${productId}`,
    keys: ["products", productId!!],
    host: process.env.REACT_APP_PRODUCTS_URL,
  });
}

function _usePatch<T1, T2, T3>({
  path,
  keys,
  resetKeys,
  host,
  replacePathParams,
}: {
  host?: string;
  path: string;
  keys?: string[];
  resetKeys?: string[];
  replacePathParams?: string;
}) {
  const _host = host ?? process.env.REACT_APP_BE_URL;
  let _url = `${_host}/${path}`;

  let headers: any = {
    "Content-Type": "application/json",
  };
  headers["Authorization"] = localStorage.getItem(
    EXHUT_BIZ_LOCAL_STORAGE_TOKEN,
  );

  const queryClient = useQueryClient();

  return useMutation<T1, T2, T3>({
    mutationFn: async (req) => {
      if (replacePathParams) {
        _url = _url.replace(
          `:${replacePathParams}`,
          (req as any)[replacePathParams],
        );

        const copy = { ...req };
        // @ts-ignore
        delete delete (copy as any)[replacePathParams];
        req = copy;
      }

      return axios
        .patch(_url, req, {
          headers,
        })
        .then((resp) => resp.data);
    },
    mutationKey: keys,
    onSuccess: () => queryClient.invalidateQueries({ queryKey: resetKeys }),
  });
}
