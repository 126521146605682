import { Button, Divider, Flex, Input } from "antd";
import Alert from "antd/es/alert/Alert";
import { useFormik } from "formik";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { ICreateInvoicePayload } from "../../../api/api";
import { useGetInvoiceById } from "../../../api/api.get";
import { updateUpdateInvoice } from "../../../api/api.update";
import ErrorPanel from "../../../components/error.panel.component";
import { useNotifications } from "../../../components/notifications/notification";
import { ProductQuantityRowItem } from "../../../components/product-quantity.component";
import { useCurrency } from "../../../hooks/useCurrency";
import { IProduct, IProductQuanityRowItem } from "../../../types/types";
import EditInvoicePayments from "./edit-invoice-payments";
import ViewInvoiceModal from "./view-invoice-modal";

export default function EditInvoice() {
  const { id, iid } = useParams();
  const { areaCode, toAmount } = useCurrency();
  const { notifySuccess, notification, notifyError } = useNotifications({
    title: "Success",
    subtitle: "Payment created successfully.",
  });

  const [invoiceItems, setInvoiceItems] = useState<IProductQuanityRowItem[]>(
    [],
  );

  const { data: invoiceData, refetch: refetchInvoiceData } = useGetInvoiceById(
    id,
    iid,
  );

  const [isPrintMode, setPrintMode] = useState<boolean>(false);

  const { mutateAsync, isPending } = updateUpdateInvoice(id, iid);

  const formik = useFormik({
    initialValues: {
      total: invoiceData?.total ?? 0,
      tax: invoiceData?.tax ?? "",
      customerFirstName: invoiceData?.customer?.firstName ?? "",
      customerLastName: invoiceData?.customer?.lastName ?? "",
      customerStreet1: invoiceData?.customer?.address?.street1 ?? "",
      customerStreet2: invoiceData?.customer?.address?.street2 ?? "",
      customerCity: invoiceData?.customer?.address?.city ?? "",
      customerState: invoiceData?.customer?.address?.state ?? "",
      customerCountryCode: invoiceData?.customer?.address?.countryCode ?? "",
      customerZipCode: invoiceData?.customer?.address?.zipCode ?? "",
      customerPhone: invoiceData?.customer?.phone ?? "",
      customerEmail: invoiceData?.customer?.email ?? "",
      items: invoiceData?.items,
      notes: invoiceData?.notes ?? "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      total: Yup.number(),
      tax: Yup.string()
        .matches(/^(0|[1-9]\d*)(\.\d+)?$/, "Please enter valid number")
        .required("Please enter amount"),
      customerFirstName: Yup.string().required("Please enter customer name"),
      customerPhone: Yup.string()
        .matches(/^(0|[1-9]\d*)(\.\d+)?$/, "Please enter valid phone number")
        .required("Please enter phone number"),
      customerEmail: Yup.string(),
      customerZipCode: Yup.string().matches(
        /^(0|[1-9]\d*)(\.\d+)?$/,
        "Please enter valid zip code",
      ),
    }),
    onSubmit: async (values) => {
      const payload: ICreateInvoicePayload = {
        items: invoiceItems,
        tax: +values.tax,
        total: values.total,
        notes: values.notes,
        customer: {
          firstName: values.customerFirstName,
          lastName: values.customerLastName,
          email: values.customerEmail,
          phone: values.customerPhone,
          address: {
            street1: values.customerStreet1,
            street2: values.customerStreet2,
            city: values.customerCity,
            state: values.customerState,
            countryCode: values.customerCountryCode,
            zipCode: values.customerZipCode,
          },
        },
      };

      mutateAsync(payload)
        .then(() => {
          formik.resetForm();
          notifySuccess();
        })
        .catch(() => {
          notifyError();
        });
    },
  });

  useEffect(() => {
    if (invoiceData) {
      setInvoiceItems(invoiceData.items);
    }
  }, [invoiceData]);

  const netTotal = useMemo(() => {
    return invoiceItems.reduce(
      (acc, item) => acc + (item.product?.price?.value ?? 0) * item.quantity,
      0,
    );
  }, [invoiceItems]);

  useEffect(() => {
    let grossTotal = 0;
    if (+formik.values.tax > 0) {
      grossTotal += netTotal + (+formik.values.tax / 100) * netTotal;
    }
    formik.setFieldValue("total", grossTotal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [netTotal, formik.values.tax]);

  const navigate = useNavigate();

  const paymentsTotal = useMemo(() => {
    return invoiceData?.payments.reduce(
      (acc, payment) => acc + payment.amount,
      0,
    );
  }, [invoiceData]);

  const paymentStatus: "error" | "warning" | "success" | "" = useMemo(() => {
    if (paymentsTotal && invoiceData) {
      if (paymentsTotal > 0) {
        if (paymentsTotal > invoiceData.total) {
          return "success";
        } else {
          return "warning";
        }
      }
      return "error";
    }
    return "error";
  }, [paymentsTotal, invoiceData]);

  const canInvoiceEdit = useMemo(() => {
    if (invoiceData?.payments) {
      return invoiceData.payments.length === 0;
    }
  }, [invoiceData?.payments]);

  const handleAddStockRowItem = () => {
    setInvoiceItems([...invoiceItems, { product: null, quantity: 0 }]);
  };

  const handleRemoveItem = (index: number) => {
    const _items = [...invoiceItems];
    _items.splice(index, 1);
    setInvoiceItems([..._items]);
  };

  const handleOnQuantityChange = (index: number, quantity: number) => {
    const items = [...invoiceItems];
    const item = items[index];
    const newItem = {
      ...item,
      quantity,
    };
    items.splice(index, 1, newItem);
    setInvoiceItems(items);
  };

  const handleOnProductChange = (index: number, product: IProduct) => {
    const items = [...invoiceItems];
    const item = items[index];
    const newItem = {
      ...item,
      product,
    };
    items.splice(index, 1, newItem);
    setInvoiceItems(items);
  };

  return (
    <div
      style={{ marginBottom: 100, backgroundColor: "var(--exhut-light-grey5)" }}
      className="divColumn p-40 flex-1"
    >
      <ViewInvoiceModal
        open={isPrintMode}
        onCancel={() => setPrintMode(false)}
        invoice={invoiceData}
        paymentsTotal={paymentsTotal}
        netTotal={netTotal}
      />

      {!canInvoiceEdit && (
        <div className="divCenterAlign p-10">
          <Alert
            type="error"
            message="The items cannot be changed since it has active payments."
          />
        </div>
      )}

      <div className="card mt-20 bg-white">
        <div className="divSpread mb-40">
          <Button
            type="link"
            onClick={() => navigate(`/organizations/${id}/invoices`)}
          >
            Back
          </Button>

          <Flex gap={20}>
            <Button type="dashed" onClick={() => setPrintMode(true)}>
              Print
            </Button>

            <Button
              loading={isPending}
              onClick={formik.submitForm}
              type="primary"
              disabled={!canInvoiceEdit}
            >
              Save
            </Button>
          </Flex>
        </div>

        <div className="divSpread divAlignItemsOnly">
          <h1>Invoice </h1>
          <span className="infoMsg">#{invoiceData?.externalId}</span>
        </div>

        <Flex className="my-20 divSpread" gap={20}>
          <div>
            <Alert
              type={paymentStatus}
              message={`Paid ${toAmount(paymentsTotal)}`}
            />
          </div>
        </Flex>

        <Divider />

        <div className="mt-20">
          <Flex gap={20} vertical className="my-40">
            <h3>Items</h3>
            <Flex gap={20} vertical>
              {invoiceItems.map(
                (item: IProductQuanityRowItem, index: number) => {
                  return (
                    <ProductQuantityRowItem
                      key={index}
                      item={item}
                      index={index}
                      onRemoveItem={(key) => handleRemoveItem(key)}
                      onQuantityChange={handleOnQuantityChange}
                      onProductChange={handleOnProductChange}
                    />
                  );
                },
              )}
            </Flex>
            <div className="my-0">
              <Button onClick={() => handleAddStockRowItem()} type="link">
                Add Item
              </Button>
            </div>
          </Flex>
        </div>

        <Divider />

        <form onSubmit={formik.handleSubmit}>
          {notification}
          <Flex gap={30} vertical>
            <div>
              <h4>Tax</h4>
              <Input
                prefix={"%"}
                disabled={!canInvoiceEdit}
                placeholder="Taxes"
                {...formik.getFieldProps("tax")}
              />
              <ErrorPanel message={formik.errors.tax} />
            </div>

            <div>
              <span>Total</span>
              <h1> ${formik.values.total.toFixed(2)}</h1>
            </div>

            <div>
              <h4 className="mb-40">Customer Details</h4>

              <Flex gap={50}>
                <Flex gap={30} vertical>
                  <Flex gap={10} vertical>
                    <span>First Name</span>
                    <Input
                      status={
                        !!formik.errors.customerFirstName ? "error" : undefined
                      }
                      {...formik.getFieldProps("customerFirstName")}
                    />
                  </Flex>

                  <Flex gap={10} vertical>
                    <span>Last Name</span>
                    <Input {...formik.getFieldProps("customerLastName")} />
                  </Flex>
                  <Flex gap={10} vertical>
                    <span>Email</span>
                    <Input {...formik.getFieldProps("customerEmail")} />
                  </Flex>

                  <Flex gap={10} vertical>
                    <span>Phone</span>
                    <Input
                      maxLength={10}
                      prefix={areaCode}
                      status={
                        !!formik.errors.customerPhone ? "error" : undefined
                      }
                      {...formik.getFieldProps("customerPhone")}
                    />
                    <ErrorPanel message={formik.errors.customerPhone} />
                  </Flex>
                </Flex>

                <Flex gap={30} vertical>
                  <Flex gap={10} vertical>
                    <span>Street 1</span>
                    <Input {...formik.getFieldProps("customerStreet1")} />
                  </Flex>

                  <Flex gap={10} vertical>
                    <span>Street 2</span>
                    <Input {...formik.getFieldProps("customerStreet2")} />
                  </Flex>
                  <Flex gap={10} vertical>
                    <span>City</span>
                    <Input {...formik.getFieldProps("customerCity")} />
                  </Flex>

                  <Flex gap={10} vertical>
                    <span>State</span>
                    <Input {...formik.getFieldProps("customerState")} />
                  </Flex>
                </Flex>

                <Flex gap={30} vertical>
                  <Flex gap={10} vertical>
                    <span>Country</span>
                    <Input {...formik.getFieldProps("customerCountryCode")} />
                  </Flex>

                  <Flex gap={10} vertical>
                    <span>Zip Code</span>
                    <Input {...formik.getFieldProps("customerZipCode")} />
                    <ErrorPanel message={formik.errors.customerZipCode} />
                  </Flex>
                </Flex>
              </Flex>
            </div>

            <div>
              <h4>Notes</h4>

              <Input.TextArea
                placeholder="Notes"
                {...formik.getFieldProps("notes")}
              />
            </div>
          </Flex>

          <EditInvoicePayments
            invoiceData={invoiceData}
            refetchInvoiceData={refetchInvoiceData}
          />
        </form>
      </div>
    </div>
  );
}
