import { DatePicker, Flex, Input, Modal, Select } from "antd";
import { useFormik } from "formik";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { useCreatePayment } from "../../../api/api";
import ErrorPanel from "../../../components/error.panel.component";
import { useCurrency } from "../../../hooks/useCurrency";
import { toSQL } from "../../../utils/date.utils";
import { useGetInvoicePaymentsTotal } from "../../../api/api.get";
import { useMemo } from "react";
import { IInvoice } from "../../../types/types";

export default function AddPaymentModal({
  invoice,
  open,
  onCancel,
}: {
  invoice?: IInvoice;
  open: boolean;
  onCancel: () => void;
}) {
  const { id } = useParams();
  const { currency, areaCode, toAmount } = useCurrency();

  const { mutateAsync, isPending } = useCreatePayment(id);

  const { data: paymentsTotal } = useGetInvoicePaymentsTotal(id, invoice?.id);

  const maxPaymentAmount: number = useMemo(
    () => (invoice?.total ?? 0) - (paymentsTotal?.total ?? 0),
    [paymentsTotal?.total, invoice?.total],
  );

  const formik = useFormik({
    initialValues: {
      paymentType: "",
      invoiceId: "",
      amount: 0,
      paymentDate: "",
      notes: "",
      customerPhone: "",
    },
    validationSchema: Yup.object({
      paymentType: Yup.string().required("Please select type"),
      paymentDate: Yup.string().required("Please select date"),
      amount: Yup.number()
        .required("Please add amount")
        .typeError("Please enter valid amount")
        .min(1, "Please enter valid amount")
        .max(
          maxPaymentAmount,
          "Payment amount cannot exceed balance amount " +
            toAmount(maxPaymentAmount),
        ),
    }),
    onSubmit: async (values) => {
      mutateAsync({
        ...values,
        invoiceId: +invoice?.id!!,
        customerPhone: `${areaCode}${formik.values.customerPhone}`,
        paymentDate: toSQL(formik.values.paymentDate),
      })
        .then(() => {
          formik.resetForm();
          onCancel();
        })
        .catch(console.error);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Modal
        title="Add Payment"
        open={open}
        onOk={formik.submitForm}
        onCancel={() => {
          formik.resetForm();
          onCancel();
        }}
        okButtonProps={{ loading: isPending }}
      >
        <Flex gap={30} vertical className="my-40">
          <Select
            placeholder={"Select payment type"}
            onChange={(value) => formik.setFieldValue("paymentType", value)}
            style={{ width: 200 }}
            options={[
              { value: "CASH", label: "Cash" },
              { value: "CARD", label: "Card" },
              { value: "CHECK", label: "Check" },

              { value: "OTHER", label: "Other" },
            ]}
          />

          <div>
            <Input
              prefix={currency}
              placeholder="Amount"
              {...formik.getFieldProps("amount")}
            />
            <ErrorPanel message={formik.errors.amount} />
          </div>

          <div>
            <DatePicker
              onChange={(value) => formik.setFieldValue("paymentDate", value)}
              value={formik.values.paymentDate}
            />

            <ErrorPanel message={formik.errors.paymentDate} />
          </div>

          <div>
            <Input
              prefix={areaCode}
              placeholder="Customer Phone"
              {...formik.getFieldProps("customerPhone")}
            />
          </div>

          <div>
            <Input.TextArea
              placeholder="Notes"
              {...formik.getFieldProps("notes")}
            />
          </div>
        </Flex>
      </Modal>
    </form>
  );
}
