import { Button, Popconfirm, Table, Typography } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDeleteInvoice } from "../../../api/api.delete";
import { useGetInvoices } from "../../../api/api.get";
import { useMessage } from "../../../components/notifications/message";
import { useCurrency } from "../../../hooks/useCurrency";
import OrganizationDate from "../organization-date";
import * as animationData from "../../../animations/invoice-lottie.json";
import Lottie from "react-lottie";
import { capitalize } from "../../../utils/functions";

const { Title } = Typography;
const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

export default function InvoicesHomePage() {
  const { state } = useLocation();

  const { animate } = state || {};
  const { id } = useParams();
  const [date, setDate] = useState(moment());
  const { messageError, notification } = useMessage();

  const { currency } = useCurrency();
  const navigate = useNavigate();

  const {
    data: invoicesData,
    isLoading: isFetching,
    refetch,
  } = useGetInvoices(
    id,
    date.startOf("month").format("YYYY-MM-DD"),
    date.endOf("month").format("YYYY-MM-DD"),
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (animate) {
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    }
  }, [animate]);

  const { mutateAsync: deleteAsync } = useDeleteInvoice(id!!);

  const handleDateChange = (dateString: string) => {
    setDate(moment(dateString, "MMM YYYY"));
  };

  useEffect(() => {
    if (date) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  const handleDelete = (row: any) => {
    deleteAsync({ invoiceId: row.id })
      .then(() => refetch())
      .catch((err) => {
        messageError(
          err.response?.data?.message ?? "Whoops! Unable to delete data",
        );
      });
  };

  const columns = [
    {
      title: "Customer",
      key: "customerName",
      render: (row: any) => <span>{capitalize(row.customer?.firstName)}</span>,
    },
    {
      title: "Customer Phone",
      key: "customerPhone",
      render: (row: any) => <span>{row.customer.phone}</span>,
    },
    {
      title: "ID",
      key: "externalId",
      render: (row: any) => <span>{row.externalId}</span>,
    },

    {
      title: "Amount",
      dataIndex: "total",
      key: "total",
      render: (value: string) => (
        <span>
          {currency}
          {value}
        </span>
      ),
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (value: string) => (
        <span>{moment(value).format("DD MMM YYYY")}</span>
      ),
    },
    {
      title: "",
      key: "operationView",
      width: 100,
      render: (row: any) => (
        <Button
          type="link"
          onClick={() => navigate(`/organizations/${id}/invoices/${row.id}`)}
        >
          Edit
        </Button>
      ),
    },
    {
      title: "",
      key: "operation",
      width: 100,
      render: (row: any) => (
        <Popconfirm
          title="Are you sure you want to delete?"
          onConfirm={() => handleDelete(row)}
        >
          <Button danger type="text" color="danger">
            Delete
          </Button>
        </Popconfirm>
      ),
    },
  ];

  return (
    <div
      style={{ marginBottom: 100 }}
      className="body divColumn mx-40 my-40 flex-1"
    >
      {notification}

      {isLoading && (
        <div className="divCenterAlign divColumn w-100">
          <h2>Invoice instantly...</h2>
          <Lottie options={defaultOptions} height={400} width={400} />
        </div>
      )}

      {!isLoading && (
        <div className="card p-40">
          <div className="divSpread divAlighItemsOnly">
            <OrganizationDate onChange={handleDateChange} />

            <Button
              type="link"
              onClick={() => navigate(`/organizations/${id}/invoices/create`)}
            >
              Create
            </Button>
          </div>
          <div className="divSpread divAlignItemsOnly mt-40">
            <Title level={3}>Invoices</Title>
          </div>
          <Table
            loading={isFetching}
            dataSource={invoicesData}
            columns={columns}
          />
        </div>
      )}
    </div>
  );
}
