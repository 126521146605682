import { Alert, Checkbox, Flex, Input, Modal } from "antd";
import { useFormik } from "formik";
import { useState } from "react";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { useCreateProduct } from "../../../api/api";
import ErrorPanel from "../../../components/error.panel.component";
import { useNotifications } from "../../../components/notifications/notification";
import { useCurrency } from "../../../hooks/useCurrency";

export default function AddProductsModal({
  open,
  onCancel,
}: {
  open: boolean;
  onCancel: () => void;
}) {
  const { id } = useParams();
  const [error, setError] = useState<string>();
  const { currency } = useCurrency();
  const { notification } = useNotifications();

  const { currencyCode } = useCurrency();

  const { mutateAsync, isPending } = useCreateProduct(id);

  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      price: "",
      saleDate: "",
      externalId: "",
      sku: "",
      quantity: "",
      salesTaxExcluded: false,
    },
    validationSchema: Yup.object({
      title: Yup.string()
        .matches(/^[a-zA-Z0-9][a-zA-Z0-9 ]*$/, "Numbers or letters only")
        .required("Please enter product name"),
      description: Yup.string(),
      price: Yup.string()
        .matches(/^(0|[1-9]\d*)(\.\d+)?$/, "Please enter valid price")
        .required("Please enter price"),
      externalId: Yup.string().matches(
        /^[a-zA-Z0-9]*$/,
        "Numbers or letters only",
      ),
      quantity: Yup.number().typeError("Please enter valid number"),
      sku: Yup.string().matches(/^[a-zA-Z0-9]*$/, "Numbers or letters only"),
      salesTaxExcluded: Yup.bool(),
    }),
    onSubmit: async (values) => {
      setError(undefined);
      const payload = {
        title: values.title,
        description: values.description,
        price: {
          currency: currencyCode,
          value: +values.price,
        },
        externalId: values.externalId,
        sku: values.sku,
        quantity: +values.quantity,
        salesTaxExcluded: values.salesTaxExcluded,
      };

      mutateAsync(payload)
        .then(() => {
          formik.resetForm();
          onCancel();
        })
        .catch((err) => {
          if (err.response?.data?.statusCode === 409) {
            setError(
              err.response?.data?.message ?? "Whoops! Something went wrong",
            );
          } else {
            setError("Whoops! Something went wrong.");
          }
        });
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      {notification}
      <Modal
        title="Add Product"
        open={open}
        onOk={formik.submitForm}
        onCancel={onCancel}
        okButtonProps={{ loading: isPending }}
      >
        <Flex gap={30} vertical style={{ padding: 20 }}>
          <div>
            <Input placeholder="Name" {...formik.getFieldProps("title")} />
            <ErrorPanel message={formik.errors.title} />
          </div>
          <div>
            <Input.TextArea
              placeholder="Decription"
              {...formik.getFieldProps("description")}
            />
            <ErrorPanel message={formik.errors.description} />
          </div>

          <div>
            <Input
              placeholder="Quantity"
              {...formik.getFieldProps("quantity")}
            />
            <ErrorPanel message={formik.errors.quantity} />
          </div>

          <div>
            <Input
              prefix={currency}
              placeholder="Price"
              {...formik.getFieldProps("price")}
            />
            <ErrorPanel message={formik.errors.price} />
          </div>

          <div>
            <Input
              placeholder="BarCode Id"
              maxLength={100}
              {...formik.getFieldProps("externalId")}
            />
            <ErrorPanel message={formik.errors.externalId} />
          </div>

          <div>
            <Input
              maxLength={100}
              placeholder="SKU"
              {...formik.getFieldProps("sku")}
            />
            <ErrorPanel message={formik.errors.sku} />
          </div>

          <Checkbox
            onChange={(e) =>
              formik.setFieldValue("salesTaxExcluded", e.target.checked)
            }
            checked={formik.values.salesTaxExcluded}
          >
            <span>Sales Tax Excluded</span>
          </Checkbox>

          <div>{error && <Alert type="error" message={error} />}</div>
        </Flex>
      </Modal>
    </form>
  );
}
