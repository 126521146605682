import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import {
  IAggregation,
  IBusinessProfile,
  IDeduction,
  IEmployeeSalaryTotal,
  IExhutProfile,
  IInvoice,
  IOrganization,
  IOrganizationEmployee,
  IOrganizationExpense,
  IOrganizationInvestment,
  IOrganizationLogo,
  IOrganizationReward,
  IOrganizationRewardLevel,
  IOrganizationSale,
  IPayroll,
  IPayrollCycle,
  IPayrollSchedule,
  IPayrollStub,
  IProduct,
  IProductImage,
  IProfileDetails,
  IReimbursement,
  IReimbursementAttachment,
  IReimbursementLifecycle,
  IRestaurantTable,
  ISaleReturn,
  IStockRequest,
  ITimesheet,
  ITotal,
  IUser,
  IUserSearch,
} from "../types/types";
import { EXHUT_BIZ_LOCAL_STORAGE_TOKEN } from "../utils/constants";

export function useGetSupplyChainStockRequestsById(
  orgId?: string,
  stockReqId?: string,
) {
  return _useGet<IStockRequest>(
    `organizations/${orgId}/supplychain/stock-requests/${stockReqId}`,
    ["supplychain", "stock-requests", stockReqId],
    !!orgId && !!stockReqId,
  );
}

export function useGetSupplyChainStockRequests(orgId?: string) {
  return _useGet<IStockRequest[]>(
    `organizations/${orgId}/supplychain/stock-requests`,
    ["supplychain", "stock-requests"],
    !!orgId,
  );
}

export function useGetAllOrganizations() {
  return _useGet<IBusinessProfile[]>(`organizations`, ["organizations"], true);
}

export function useGetOrganizationLogoById(id?: string) {
  return _useGet<IOrganizationLogo>(
    `organizations/${id}/logo`,
    ["organizations" + id + "logo"],
    !!id,
  );
}

export function useGetOrganizationById(id?: string) {
  return _useGet<IOrganization>(
    `organizations/${id}`,
    ["organizations" + id],
    !!id,
  );
}

export function useGetProfileOnOrganization(orgId?: string) {
  return _useGet<IProfileDetails>(
    `organizations/${orgId}/profile`,
    ["organizations", orgId, "profile"],
    !!orgId,
  );
}

export function useGetOrganizationExpenses(
  orgId?: string,
  from?: string,
  to?: string,
) {
  return _useGet<IOrganizationExpense[]>(
    `organizations/${orgId}/expenses?from=${from}&to=${to}`,
    ["organizations", orgId, "expenses"],
    !!orgId && !!from && !!to,
  );
}

export function useGetInvoices(orgId?: string, from?: string, to?: string) {
  return _useGet<IInvoice[]>(
    `organizations/${orgId}/invoices?from=${from}&to=${to}`,
    ["organizations", orgId, "invoices"],
    !!orgId && !!from && !!to,
  );
}

export function useGetInvoiceById(orgId?: string, iid?: string) {
  return _useGet<IInvoice>(
    `organizations/${orgId}/invoices/${iid}`,
    ["organizations", orgId, "invoices", iid],
    !!orgId && !!iid,
  );
}

export function useGetInvoicePaymentsTotal(orgId?: string, iid?: string) {
  return _useGet<ITotal>(
    `organizations/${orgId}/payments/total?invoiceId=${iid}`,
    ["organizations", orgId, "payments", iid],
    !!orgId && !!iid,
  );
}

export function useGetTimeSheets(orgId?: string, from?: string, to?: string) {
  return _useGet<ITimesheet[]>(
    `organizations/${orgId}/timesheets?from=${from}&to=${to}`,
    ["organizations", orgId, "timesheets"],
    !!orgId && !!from && !!to,
  );
}

export function useGetRewardTotal(
  orgId?: string,
  areaCode?: string,
  customerPhone?: string,
) {
  const query =
    areaCode && customerPhone ? `phone=${areaCode + customerPhone}` : "";
  return _useGet<ITotal>(
    `organizations/${orgId}/rewards/total?${query}`,
    ["organizations", orgId, "rewards", "total"],
    !!orgId && !!customerPhone && !!areaCode,
  );
}

export function useGetPointsRewardLevels(orgId?: string) {
  return _useGet<IOrganizationRewardLevel[]>(
    `organizations/${orgId}/rewards-level?type=POINTS`,
    ["organizations", orgId, "rewards-level"],
    !!orgId,
  );
}

export function useGetRewardLevels(orgId?: string, enabled?: "true" | "false") {
  const query = enabled ? `?enabled=${enabled}` : "";
  return _useGet<IOrganizationRewardLevel[]>(
    `organizations/${orgId}/rewards-level${query}`,
    ["organizations", orgId, "rewards-level"],
    !!orgId,
  );
}

export function useSearchRewardLevelByExternalId(
  orgId?: string,
  externalId?: string,
) {
  return _useGet<IOrganizationRewardLevel>(
    `organizations/${orgId}/rewards-level/search?code=${externalId}`,
    ["organizations", orgId, "rewards-level", "code", externalId],
    !!orgId && !!externalId,
  );
}

export function useGetRewards(orgId?: string, from?: string, to?: string) {
  return _useGet<IOrganizationReward[]>(
    `organizations/${orgId}/rewards?from=${from}&to=${to}`,
    ["organizations", orgId, "rewards"],
    !!orgId && !!from && !!to,
  );
}

export function useGetRewardsById(orgId?: string, rid?: string) {
  return _useGet<IOrganizationReward>(
    `organizations/${orgId}/rewards/${rid}`,
    ["organizations", orgId, "rewards", rid],
    !!orgId && !!rid,
  );
}

export function useGetRewardLevelsById(orgId?: string, id?: string) {
  return _useGet<IOrganizationRewardLevel>(
    `organizations/${orgId}/rewards-level/${id}`,
    ["organizations", orgId, "rewards-level", id],
    !!orgId && !!id,
  );
}

export function useGetReimbursements(
  orgId?: string,
  from?: string,
  to?: string,
) {
  return _useGet<IReimbursement[]>(
    `organizations/${orgId}/reimbursements?from=${from}&to=${to}`,
    ["organizations", orgId, "reimbursements", from, to],
    !!orgId && !!from && !!to,
  );
}

export function useGetReimbursementById(orgId?: string, id?: string) {
  return _useGet<IReimbursement>(
    `organizations/${orgId}/reimbursements/${id}`,
    ["organizations", orgId, "reimbursements", id],
    !!orgId && !!id,
  );
}

export function useGetReimbursementAttachments(orgId?: string, id?: string) {
  return _useGet<IReimbursementAttachment[]>(
    `organizations/${orgId}/reimbursements/${id}/attachments`,
    ["organizations", orgId, "reimbursements", id, "attachments"],
    !!orgId && !!id,
  );
}

export function useGetReimbursementsLifecycle(orgId?: string, id?: string) {
  return _useGet<IReimbursementLifecycle[]>(
    `organizations/${orgId}/reimbursements/${id}/lifecycle`,
    ["organizations", orgId, "reimbursements", id, "lifecycle"],
    !!orgId && !!id,
  );
}

export function useGetTimeSheetsTotal(
  orgId?: string,
  from?: string,
  to?: string,
) {
  return _useGet<ITotal>(
    `organizations/${orgId}/timesheets/total?from=${from}&to=${to}`,
    ["organizations", orgId, "timesheets", "total"],
    !!orgId && !!from && !!to,
  );
}

export function useGetTimeSheetsTotalForEmployee(
  orgId?: string,
  date?: string,
) {
  return _useGet<ITotal>(
    `organizations/${orgId}/timesheets/total?date=${date}`,
    ["organizations", orgId, "timesheets", "total", date],
    !!orgId && !!date,
  );
}

export function useGetInvestments(orgId?: string, from?: string, to?: string) {
  return _useGet<IOrganizationInvestment[]>(
    `organizations/${orgId}/investments?from=${from}&to=${to}`,
    ["organizations", orgId, "investments", from, to],
    !!orgId,
  );
}

export function useGetPayrollSchedule(orgId?: string) {
  return _useGet<IPayrollSchedule>(
    `organizations/${orgId}/payroll-schedules`,
    ["organizations", orgId, "payroll-schedules"],
    !!orgId,
  );
}

export function useGetPayrollCycles(orgId?: string) {
  return _useGet<IPayrollCycle[]>(
    `organizations/${orgId}/payroll-cycles`,
    ["organizations", orgId, "payroll-cycles"],
    !!orgId,
  );
}

export function useGetPayrollCyclesById(orgId?: string, id?: string) {
  return _useGet<IPayrollCycle>(
    `organizations/${orgId}/payroll-cycles/${id}`,
    ["organizations", orgId, "payroll-cycles", id],
    !!orgId && !!id,
  );
}

export function useGetPayrollBonuses(orgId?: string, cycleId?: string) {
  return _useGet<IPayroll[]>(
    `organizations/${orgId}/payroll-cycles/${cycleId}/bonuses`,
    ["organizations", orgId, "payroll-cycles", cycleId, "bonuses"],
    !!orgId && !!cycleId,
  );
}

export function useGetPayrolls(orgId?: string) {
  return _useGet<IPayroll[]>(
    `organizations/${orgId}/payroll`,
    ["organizations", orgId, "payroll"],
    !!orgId,
  );
}

export function useGetPayrollsForAdmin(orgId?: string, cycleId?: string) {
  return _useGet<IPayroll[]>(
    `organizations/${orgId}/payroll?cycleId=${cycleId}`,
    ["organizations", orgId, "payroll", cycleId],
    !!orgId && !!cycleId,
  );
}

export function useGetPayrollById(orgId?: string, payrollId?: number) {
  return _useGet<IPayrollStub>(
    `organizations/${orgId}/payroll/${payrollId}`,
    ["organizations", orgId, "payroll", payrollId?.toString()],
    !!orgId && !!payrollId,
  );
}

export function useGetOrganizationInvestmentsTotal(
  orgId?: string,
  date?: string,
) {
  return _useGet<ITotal>(
    `organizations/${orgId}/investments/total?date=${date}`,
    ["organizations", orgId, "investments", "total", date],
    !!orgId,
  );
}

export function useGetSales(orgId?: string, from?: string, to?: string) {
  return _useGet<IOrganizationSale[]>(
    `organizations/${orgId}/sales?from=${from}&to=${to}`,
    ["organizations", orgId, "sales"],
    !!orgId && !!from && !!to,
  );
}

export function useGetSalesReturns(orgId?: string, saleId?: string) {
  return _useGet<ISaleReturn[]>(
    `organizations/${orgId}/sales/${saleId}/returns`,
    ["organizations", orgId, "sales", saleId, "returns"],
    !!orgId && !!saleId,
  );
}

export function useGetReturnEligibleProducts(orgId?: string, saleId?: string) {
  return _useGet<string[]>(
    `organizations/${orgId}/sales/${saleId}/returns/eligible-products`,
    ["organizations", orgId, "sales", saleId, "returns", "eligible-products"],
    !!orgId && !!saleId,
  );
}

export function useGetOrganizationSalesById(orgId?: string, saleId?: string) {
  return _useGet<IOrganizationSale>(
    `organizations/${orgId}/sales/${saleId}`,
    ["organizations", orgId, "sales", saleId],
    !!orgId && !!saleId,
  );
}

export function useGetSalesAggregation(orgId?: string) {
  return _useGet<IAggregation[]>(
    `organizations/${orgId}/sales/aggregation`,
    ["organizations", orgId, "sales", "aggregation"],
    !!orgId,
  );
}

export function useGetExpensesAggregation(orgId?: string, previous?: string) {
  return _useGet<IAggregation[]>(
    `organizations/${orgId}/expenses/aggregation?previous=12`,
    ["organizations", orgId, "expenses", "aggregation"],
    !!orgId,
  );
}

export function useGetOrganizationEmployees(orgId?: string) {
  return _useGet<IOrganizationEmployee[]>(
    `organizations/${orgId}/employees`,
    ["organizations" + orgId, "employees"],
    !!orgId,
  );
}

export function useGetOrganizationEmployeesSalaryTotal(
  orgId?: string,
  from?: string,
  to?: string,
) {
  return _useGet<ITotal>(
    `organizations/${orgId}/employees/salary/total?from=${from}&to=${to}`,
    ["organizations" + orgId, "employees", "salary", "total", from, to],
    !!orgId && !!from && !!to,
  );
}

export function useGetOrganizationEmployeesSalary(
  orgId?: string,
  from?: string,
  to?: string,
) {
  return _useGet<IEmployeeSalaryTotal[]>(
    `organizations/${orgId}/employees/salary?from=${from}&to=${to}`,
    ["organizations" + orgId, "employees", "salary"],
    !!orgId && !!from && !!to,
  );
}

export function useGetOrganizationEmployeesById(
  orgId?: string,
  empId?: string,
) {
  return _useGet<IOrganizationEmployee>(
    `organizations/${orgId}/employees/${empId}`,
    ["organizations" + orgId, "employees", empId],
    !!orgId && !!empId,
  );
}

export function useGetOrganizationEmployeesByUsername(
  orgId?: string,
  username?: string,
) {
  return _useGet<IOrganizationEmployee[]>(
    `organizations/${orgId}/employees?username=${username}`,
    ["organizations" + orgId, "employees", username],
    !!orgId && !!username,
  );
}

export function useGetEmployeeDeductions(orgId?: string, empId?: string) {
  return _useGet<IDeduction[]>(
    `organizations/${orgId}/employees/${empId}/deductions`,
    ["organizations" + orgId, "employees", empId, "deductions"],
    !!orgId && !!empId,
  );
}

export function useGetOrganizationEmployeesTotal(orgId?: string) {
  return _useGet<ITotal>(
    `organizations/${orgId}/employees/total`,
    ["organizations" + orgId, "employees", "total"],
    !!orgId,
  );
}

export function useGetOrganizationSalesTotal(
  orgId?: string,
  from?: string,
  to?: string,
) {
  return _useGet<ITotal>(
    `organizations/${orgId}/sales/total?from=${from}&to=${to}`,
    ["organizations" + orgId, "sales", "total"],
    !!orgId && !!from && !!to,
  );
}

export function useGetOrganizationExpensesTotal(
  orgId?: string,
  from?: string,
  to?: string,
) {
  return _useGet<ITotal>(
    `organizations/${orgId}/expenses/total?from=${from}&to=${to}`,
    ["organizations" + orgId, "expenses", "total"],
    !!orgId && !!from && !!to,
  );
}

export function useGetSession(enabled: boolean) {
  return _useGetAuthBE<IUser>("session", ["session"], enabled);
}

export function useSearchCustomers(phone?: string, enabled: boolean = false) {
  return _useGet<IUserSearch>(
    `search/customers?phone=${phone}`,
    ["search", "customers", phone],
    enabled,
  );
}

export function useGetMyUserDetailsFromAuthBESession() {
  return _useGetAuthBE<IExhutProfile>(
    "users/profile",
    ["users", "profile"],
    true,
  );
}

export function useGetRestaurantTables(orgId?: string) {
  return _useGet<IRestaurantTable[]>(
    `organizations/${orgId}/restaurant-tabs`,
    ["organizations", orgId, "restaurant-tabs"],
    true,
  );
}

export function useGetRestaurantTableById(orgId?: string, tableId?: string) {
  return _useGet<IRestaurantTable>(
    `organizations/${orgId}/restaurant-tabs/${tableId}`,
    ["organizations", orgId, "restaurant-tabs", tableId],
    true,
  );
}

export function useGetProducts(orgId?: string) {
  return _useGetProducts<IProduct[]>(
    `organizations/${orgId}/products`,
    ["products"],
    !!orgId,
  );
}

export function useGetProductById(orgId?: string, productId?: string) {
  return _useGetProducts<IProduct>(
    `organizations/${orgId}/products/${productId}`,
    ["products", productId],
    !!orgId,
  );
}

export function useGetProductImages(orgId?: string, productId?: string) {
  return _useGetProducts<IProductImage[]>(
    `organizations/${orgId}/products/${productId}/images`,
    ["products", productId, "images"],
    !!orgId,
  );
}

export function useSearchProducts(orgId?: string, searchText?: string) {
  return _useGetProducts<IProduct[]>(
    `organizations/${orgId}/products?name=${searchText}`,
    ["products", "search", searchText],
    !!orgId && !!searchText,
  );
}

export function useSearchAllProducts(orgId?: string, searchText?: string) {
  return _useGetProducts<IProduct[]>(
    `organizations/${orgId}/products`,
    ["products", "search", searchText],
    !!orgId,
  );
}

export function useSearchProductsByMultipleIds(orgId?: string, ids?: string[]) {
  return _useGetProducts<IProduct[]>(
    `organizations/${orgId}/products?ids=${ids?.join(",")}`,
    ["products", "search", ids?.join("-")],
    !!orgId && !!ids,
  );
}

export function useSearchProductsByBarcode(orgId?: string, barcodeId?: string) {
  return _useGetProducts<IProduct>(
    `organizations/${orgId}/products?code=${barcodeId}`,
    ["products", "barcode", barcodeId],
    !!orgId && !!barcodeId,
  );
}

function _useGetProducts<T1>(
  path: string,
  keys: (string | undefined | null)[],
  enabled?: boolean,
  authenticated: boolean = true,
) {
  return _useGet<T1>(
    path,
    keys,
    enabled,
    authenticated,
    process.env.REACT_APP_PRODUCTS_URL,
  );
}

function _useGetAuthBE<T1>(
  path: string,
  keys: (string | undefined | null)[],
  enabled?: boolean,
  authenticated: boolean = true,
) {
  return _useGet<T1>(
    path,
    keys,
    enabled,
    authenticated,
    process.env.REACT_APP_AUTH_BE_URL,
  );
}

function _useGet<T1>(
  path: string,
  keys: (string | undefined | null)[],
  enabled?: boolean,
  authenticated: boolean = true,
  host?: string,
) {
  let headers: any = {};

  const hostUrl = host ?? process.env.REACT_APP_BE_URL;

  if (authenticated) {
    headers["authorization"] = localStorage.getItem(
      EXHUT_BIZ_LOCAL_STORAGE_TOKEN,
    );
  }

  return useQuery<T1, Error>({
    queryFn: async () => {
      return axios
        .get(`${hostUrl}/${path}`, {
          headers,
        })
        .then((resp) => resp.data);
    },
    queryKey: keys,
    enabled,
    retry: 0,
  });
}
