import { Descriptions, Flex, Modal } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSearchCustomers } from "../../../api/api.get";
import { IOrganizationReward } from "../../../types/types";

export default function ViewRewardModal({
  reward,
  open,
  onCancel,
}: {
  reward: IOrganizationReward;
  open: boolean;
  onCancel: () => void;
}) {
  const { data: userData, refetch } = useSearchCustomers(reward?.phone);
  const [items, setItems] = useState<any[]>([]);

  useEffect(() => {
    if (reward) {
      refetch();
    }
  }, [reward, refetch]);

  useEffect(() => {
    const _items: any[] = [];

    if (reward) {
      _items.push({
        key: "1",
        label: "Email",
        children: userData?.email,
      });
      _items.push({
        key: "2",
        label: "Name",
        children: `${userData?.firstName ?? ""} ${userData?.lastName ?? "-"}`,
      });

      _items.push({
        key: "3",
        label: "Phone",
        children: reward.phone,
      });
      _items.push({
        key: "4",
        label: "Rewards Points",
        children: reward?.value,
      });
      _items.push({
        key: "5",
        label: "Created Date",
        children: moment(reward.created_at).format("DD MMM YYYY"),
      });

      setItems(_items);
    }
  }, [reward, userData]);

  return (
    <Modal
      title="Reward Transactions"
      open={open}
      footer={null}
      onCancel={onCancel}
    >
      <Flex gap={30} vertical>
        <Descriptions bordered layout="vertical" items={items} />
      </Flex>
    </Modal>
  );
}
