import { PlusCircleTwoTone } from "@ant-design/icons";
import { Button, DatePicker, Flex, Input, Popconfirm, Typography } from "antd";
import { useFormik } from "formik";
import moment from "moment";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { useSupplyChainCreateStockRequest } from "../../../../api/api";
import ErrorPanel from "../../../../components/error.panel.component";
import { useNotifications2 } from "../../../../components/notifications/notification";
import { ProductQuantityRowItem } from "../../../../components/product-quantity.component";
import { IProduct, IProductQuanityRowItem } from "../../../../types/types";
const { Title } = Typography;

export default function AddStockRequestPage() {
  const [stockRowItems, setStockRowItems] = useState<IProductQuanityRowItem[]>(
    [],
  );

  const { id } = useParams();
  const { notification, notifyError, notifySuccess } = useNotifications2();
  const { mutateAsync } = useSupplyChainCreateStockRequest(id);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      vendorName: "",
      vendorEmail: "",
      vendorPhone: "",
      deliveryDate: "",
      notes: "",
    },
    validationSchema: Yup.object({
      vendorName: Yup.string().required("Please enter name"),
      vendorEmail: Yup.string(),
      vendorPhone: Yup.string()
        .matches(/^[0-9]+$/, "Please enter valid number")
        .required("Please enter phone"),
      deliveryDate: Yup.string().required("Please select a delivery date"),
      notes: Yup.string(),
    }),
    onSubmit: async (values) => {
      mutateAsync({
        ...values,
        items: stockRowItems,
        status: "CREATED",
      })
        .then(() => {
          formik.resetForm();
          setStockRowItems([]);
          navigate(`/organizations/${id}/inventory`);
          notifySuccess({
            title: "Success",
            description: "Stocks requested successfully.",
          });
        })
        .catch(() => {
          notifyError(
            "Whoops! Unable to submit request. Please try after sometime.",
          );
        });
    },
  });

  const handleAddStockRowItem = () => {
    setStockRowItems([...stockRowItems, { product: null, quantity: 0 }]);
  };

  const handleRemoveItem = (index: number) => {
    const _items = [...stockRowItems];
    _items.splice(index, 1);
    setStockRowItems([..._items]);
  };

  const handleOnQuantityChange = (index: number, quantity: number) => {
    const items = [...stockRowItems];
    const item = items[index];
    const newItem = {
      ...item,
      quantity,
    };
    items.splice(index, 1, newItem);
    setStockRowItems(items);
  };

  const handleOnProductChange = (index: number, product: IProduct) => {
    const items = [...stockRowItems];
    const item = items[index];
    const newItem = {
      ...item,
      product,
    };
    items.splice(index, 1, newItem);
    setStockRowItems(items);
  };

  return (
    <div
      style={{ marginBottom: 100 }}
      className="card divColumn mx-40 my-40 flex-1"
    >
      {notification}
      <div className="divSpread divAlignItemsOnly">
        <Title level={3}>New Stock Request</Title>

        <Flex gap={10}>
          <Popconfirm
            title={"Are you sure you want to submit?"}
            onConfirm={() => formik.submitForm()}
          >
            <Button
              type="primary"
              disabled={Object.keys(formik.errors).length > 0}
            >
              Create
            </Button>
          </Popconfirm>
        </Flex>
      </div>

      <form onSubmit={formik.handleSubmit}>
        <Flex gap={20} vertical className="my-40">
          <Flex gap={10} vertical className="mb-20">
            <strong>Order Date</strong>
            <span className="infoMsg">{moment().format("DD MMM YYYY")}</span>
          </Flex>

          <Flex gap={10} vertical className="mb-20">
            <strong>Vendor Name</strong>
            <div>
              <Input
                placeholder="Description"
                {...formik.getFieldProps("vendorName")}
              />
              <ErrorPanel message={formik.errors.vendorName} />
            </div>
          </Flex>

          <Flex gap={10} vertical className="mb-20">
            <strong>Vendor Email</strong>
            <Input
              placeholder="Email"
              {...formik.getFieldProps("vendorEmail")}
            />
          </Flex>

          <Flex gap={10} vertical className="mb-20">
            <strong>Vendor Phone</strong>
            <Input
              placeholder="Phone"
              {...formik.getFieldProps("vendorPhone")}
            />
            <ErrorPanel message={formik.errors.vendorPhone} />
          </Flex>

          <Flex gap={10} vertical>
            <strong>Delivery Date</strong>
            <div>
              <DatePicker
                onChange={(value) =>
                  formik.setFieldValue("deliveryDate", value)
                }
                value={formik.values.deliveryDate}
              />

              <ErrorPanel message={formik.errors.deliveryDate} />
            </div>
          </Flex>

          <Title level={4}>Products</Title>

          <Flex gap={20} vertical className="mb-40">
            <Flex gap={20} vertical>
              {stockRowItems.map(
                (item: IProductQuanityRowItem, index: number) => {
                  return (
                    <ProductQuantityRowItem
                      key={index}
                      item={item}
                      index={index}
                      onRemoveItem={(key) => handleRemoveItem(key)}
                      onQuantityChange={handleOnQuantityChange}
                      onProductChange={handleOnProductChange}
                    />
                  );
                },
              )}
            </Flex>

            <Button
              icon={<PlusCircleTwoTone />}
              onClick={() => handleAddStockRowItem()}
            ></Button>
          </Flex>

          <Flex gap={10} vertical>
            <Input.TextArea
              placeholder="Notes..."
              {...formik.getFieldProps("notes")}
            />
          </Flex>
        </Flex>
      </form>
    </div>
  );
}
