import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useGetOrganizationById } from "../api/api.get";
import { getCountryPhoneCode } from "../utils/constants";
import { getCurrencyCode, getCurrencySymbol } from "../utils/functions";

export function useCurrency() {
  const { id } = useParams();
  const { data } = useGetOrganizationById(id);
  const [currency, setCurrency] = useState<string>();
  const [areaCode, setAreaCode] = useState<string>();
  const [currencyCode, setCurrencyCode] = useState<string>();

  useEffect(() => {
    if (data) {
      setCurrency(getCurrencySymbol(data.country));
      setAreaCode(getCountryPhoneCode(data.country));
      setCurrencyCode(getCurrencyCode(data.country));
    }
  }, [data]);

  const toAmount = (value?: number) => {
    return `${currency}${value ? value.toFixed(2) : "0.00"}`;
  };

  return { currency, areaCode, currencyCode, toAmount };
}
