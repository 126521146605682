import {
  DeleteOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
  ShoppingCartOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Card,
  Flex,
  Input,
  List,
  Modal,
  Row,
  Skeleton,
} from "antd";
import Meta from "antd/es/card/Meta";
import Select from "antd/es/select";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useDebounce } from "use-debounce";
import { useCreateOrgSale } from "../../../../api/api";
import {
  useGetOrganizationById,
  useSearchAllProducts,
  useSearchProducts,
} from "../../../../api/api.get";
import { useModalNotification } from "../../../../components/notifications/modal";
import { useCurrency } from "../../../../hooks/useCurrency";
import { IProduct } from "../../../../types/types";
import { useLayoutContext } from "../../../../providers/layout.provider";

export default function FastFoodPage() {
  const { id } = useParams();
  const [searchText, setSearchText] = useState<string>("");
  const { toAmount } = useCurrency();
  const {
    notification: modalNotification,
    notifySuccess,
    notifyError,
  } = useModalNotification();

  const [items, setItems] = useState<IProduct[]>([]);
  const { isMobile } = useLayoutContext();

  const [productsToCheckout, setProductsToCheckout] = useState<IProduct[]>([]);

  const { data: orgData } = useGetOrganizationById(id);
  const [debouncedProductNameSearchText] = useDebounce(searchText, 300);
  const [confirmCheckout, setConfirmCheckout] = useState<boolean>(false);
  const { mutateAsync, isPending } = useCreateOrgSale(id);

  const { data: productsByNameData, isLoading } = useSearchProducts(
    id,
    debouncedProductNameSearchText,
  );

  const productCountMap: Map<string, number> = useMemo(() => {
    const map = new Map<string, number>();
    for (const p of productsToCheckout) {
      const existing = map.get(p._id);
      if (existing) {
        map.set(p._id, existing + 1);
      } else {
        map.set(p._id, 1);
      }
    }
    return map;
  }, [productsToCheckout]);

  const { data: allProducts, isLoading: allProductsLoading } =
    useSearchAllProducts(id);

  useEffect(() => {
    if (debouncedProductNameSearchText && productsByNameData) {
      setItems(productsByNameData);
    } else {
      setItems(allProducts ?? []);
    }
  }, [debouncedProductNameSearchText, allProducts, productsByNameData]);

  const handleRemoveItems = (item: IProduct) => {
    const _items = [...productsToCheckout];
    const index = _items.findIndex((p) => p._id === item._id);
    _items.splice(index, 1);
    setProductsToCheckout(_items);
  };

  const itemTotal: number = productsToCheckout.reduce(
    (acc, item) => acc + (item.price?.value ?? 0),
    0,
  );

  const handleAddItems = (item: IProduct) => {
    const existingProductsToCheckout = [...productsToCheckout];
    existingProductsToCheckout.push(item);
    setProductsToCheckout(existingProductsToCheckout);
  };

  const handleCheckout = (paymentType: string, phone?: string) => {
    setConfirmCheckout(false);
    mutateAsync({
      amount: itemTotal,
      saleDate: moment().format("YYYY-MM-DD"),
      category: items?.[0].title ?? "Scan Order - unknown",
      products: items.map((item) => item._id),
      tax: orgData?.salesTax ?? 0,
      phone,
      paymentType,
      type: "restaurant",
    })
      .then(() => {
        notifySuccess({
          title: "Success",
          description: "Order successfully created!",
        });

        setConfirmCheckout(false);
        setProductsToCheckout([]);
      })
      .catch(() =>
        notifyError({ description: "Something went wrong! Please try again." }),
      );
  };

  return (
    <div className="w-100 divFlex p-20" style={{ height: "100%" }}>
      {modalNotification}

      <ConfirmCheckout
        itemTotal={itemTotal}
        tax={orgData?.salesTax}
        open={confirmCheckout}
        onOK={(paymentType) => handleCheckout(paymentType!!)}
        onCancel={() => setConfirmCheckout(false)}
        items={productsToCheckout}
        onRemoveItems={(item: IProduct) => handleRemoveItems(item)}
      />
      <div className="">
        <div className="divSpread mb-20">
          <Input
            onChange={(e) => setSearchText(e.target.value)}
            value={searchText}
            allowClear
            style={{ width: "50%" }}
          />

          <Flex gap={5} className="divAlignItemsOnly">
            <strong>{toAmount(itemTotal)}</strong>

            <Button
              type="primary"
              onClick={() => setConfirmCheckout(true)}
              disabled={productsToCheckout.length === 0}
              loading={isPending}
              icon={<ShoppingCartOutlined />}
            >
              {productsToCheckout.length}
            </Button>
          </Flex>
        </div>

        <div
          className="card"
          style={{
            margin: 0,
            backgroundColor: "var(--exhut-light-grey5)",
          }}
        >
          <Skeleton loading={isPending}>
            <Row gutter={16}>
              <Skeleton loading={isLoading || allProductsLoading}>
                {items.map((item: IProduct, index: number) => {
                  return (
                    <Card
                      key={index}
                      style={{
                        width: isMobile ? "auto" : 200,
                        marginLeft: 10,
                        marginRight: 10,
                        padding: 10,
                        marginTop: 10,
                        marginBottom: 10,
                      }}
                      cover={<img alt="example" src={item.cover} />}
                      actions={
                        productsToCheckout.map((p) => p._id).includes(item._id)
                          ? [
                              <MinusCircleOutlined
                                onClick={() => handleRemoveItems(item)}
                              />,
                              <Button icon={<ShoppingCartOutlined />}>
                                {productCountMap.get(item._id)}
                              </Button>,
                              <PlusCircleOutlined
                                onClick={() => handleAddItems(item)}
                              />,
                            ]
                          : [
                              <Button
                                icon={<ShoppingCartOutlined />}
                                onClick={() => handleAddItems(item)}
                              >
                                Add To Cart
                              </Button>,
                            ]
                      }
                    >
                      <Meta
                        title={item.title}
                        description={toAmount(item.price?.value)}
                      />
                    </Card>
                  );
                })}
              </Skeleton>
            </Row>
          </Skeleton>
        </div>
      </div>
    </div>
  );
}

function ConfirmCheckout({
  open,
  itemTotal,
  tax,
  onCancel,
  onOK,
  items,
  onRemoveItems,
}: {
  items: IProduct[];
  itemTotal: number;
  tax?: number;
  open: boolean;
  onOK: (paymentType?: string, phone?: string) => void;
  onCancel: () => void;
  onRemoveItems: (item: IProduct) => void;
}) {
  const [paymentType, setPaymentType] = useState<string>();
  const [phone, setPhone] = useState<string>();
  const { toAmount } = useCurrency();

  const grandTotal = useMemo(() => {
    // items can have sales tax excluded
    const orgSalesTax = tax ?? 0;

    return items.reduce((acc, item) => {
      const itemPrice = item.price?.value ?? 0;

      if (item.salesTaxExcluded === true) {
        return acc + itemPrice;
      }
      return acc + itemPrice + (itemPrice * orgSalesTax) / 100;
    }, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemTotal]);

  return (
    <div>
      <Modal
        title="Checkout"
        open={open}
        onOk={() => onOK(paymentType, phone)}
        onCancel={onCancel}
      >
        <div className="divColumn">
          <div className="my-20">
            <List
              dataSource={items}
              itemLayout="horizontal"
              bordered
              renderItem={(item, index) => (
                <List.Item key={index}>
                  <List.Item.Meta
                    avatar={<Avatar src={item.cover} />}
                    title={<a href="https://ant.design">{item.title}</a>}
                    description={"$" + item.price?.value}
                  />

                  <Flex gap={20}>
                    <DeleteOutlined
                      style={{ color: "red" }}
                      onClick={() => onRemoveItems(item)}
                    />
                  </Flex>
                </List.Item>
              )}
            />
          </div>
          <Flex className="divSpread">
            <Flex vertical>
              <Flex gap={10} className="divAlignItemsOnly">
                <strong className="infoMsg">Total</strong>
                <h3>{toAmount(itemTotal)} </h3>
              </Flex>

              <Flex gap={20} className="divAlignItemsOnly">
                <strong className="infoMsg">Tax</strong>
                <h3>{tax}%</h3>
              </Flex>
            </Flex>

            <Flex gap={20} className="divAlignItemsOnly">
              <h1>{toAmount(grandTotal)} </h1>
            </Flex>
          </Flex>

          <Flex vertical gap={10} className="my-40">
            <Flex vertical gap={10}>
              <span>Select Payment Type</span>
              <Select
                id="first-time-select"
                defaultValue=""
                onChange={(value) => setPaymentType(value)}
                options={[
                  { value: "cash", label: "Cash" },
                  {
                    value: "card",
                    label: "Credit Card",
                  },
                ]}
              />
            </Flex>

            <Flex vertical gap={10}>
              <span>Customer Phone</span>
              <Input value={phone} onChange={(e) => setPhone(e.target.value)} />
            </Flex>
          </Flex>
        </div>
      </Modal>
    </div>
  );
}
