import { Route, Routes } from "react-router-dom";
import LeftPaneComponent from "./components/leftpane/leftpane.component";
import {
  RequireEmployeeAccess,
  RequireOrgAdminAccess,
} from "./higher-order/require-access";
import { RequireSalesSettingsOrAdmin } from "./higher-order/require-settings";
import HomePage from "./pages/home.page";
import NoAccessPage from "./pages/no-access.page";
import OrganizationEmployeesHomePage from "./pages/organizations/employees/employees-home";
import ViewEmployeePage from "./pages/organizations/employees/view-employee.page";
import OrganizationExpensesHomePage from "./pages/organizations/expenses/expenses-home";
import OrganizationInvestmentsHomePage from "./pages/organizations/investments/investments-home";
import EditInvoice from "./pages/organizations/invoices/edit-invoice";
import InvoicesHomePage from "./pages/organizations/invoices/invoices.home";
import OrganizationHomePage from "./pages/organizations/organization-home";
import PayrollCycleHomePage from "./pages/organizations/payroll/payroll-cycle-home";
import PayrollHomePage from "./pages/organizations/payroll/payroll-home";
import PayrollHomEmployeePage from "./pages/organizations/payroll/payroll-home-employee";
import ProductsEditPage from "./pages/organizations/products/products-edit";
import ProductsHomePage from "./pages/organizations/products/products-home";
import ReimbursementsHomePage from "./pages/organizations/reimbursements/reimbursements-home";
import RewardsHomePage from "./pages/organizations/rewards/reward-home";
import OrganizationSalesHomePage from "./pages/organizations/sales/sales-home";
import OrganizationSettings from "./pages/organizations/settings";
import OrganizationTaxesSettingsPage from "./pages/organizations/settings/taxes-settings.page";
import TimeSheetsHomePage from "./pages/organizations/timesheets/timesheet-home";
import Profile from "./pages/home/profile.page";
import CheckoutPage from "./pages/organizations/checkout";
import ReturnsPage from "./pages/organizations/sales/returns";
import RestaurantsHomePage from "./pages/organizations/sales/restaurants/restaurants.home";
import FastFoodPage from "./pages/organizations/sales/restaurants/fastfood";
import RestaurantTabPage from "./pages/organizations/sales/restaurants/restaurants.tab";
import StockRequestsPage from "./pages/organizations/supplychain/stock-requests/stock-requests.page";
import AddStockRequestPage from "./pages/organizations/supplychain/stock-requests/add-stock-request.page";
import EditStockRequestPage from "./pages/organizations/supplychain/stock-requests/edit-stock-request.page";
import AddInvoice2 from "./pages/organizations/invoices/add-invoice";

export const AuthenticatedRoutes = () => {
  return (
    <div className="divFlex">
      <Routes>
        <Route path="/home" element={<HomePage />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/organizations/:id/*" element={<OrganizationRoutes />} />
        <Route path="*" element={<NoAccessPage />} />
      </Routes>
    </div>
  );
};

const OrganizationRoutes = () => {
  const employeeRoutes = (
    <RequireEmployeeAccess>
      <Routes>
        <Route path="/" element={<TimeSheetsHomePage />} />
      </Routes>
    </RequireEmployeeAccess>
  );
  const orgAdminRoutes = (
    <RequireOrgAdminAccess>
      <Routes>
        <Route path="/payroll-cycles/:pid" element={<PayrollCycleHomePage />} />

        <Route path="/payroll-cycles" element={<PayrollHomePage />} />
        <Route path="/products/:pid" element={<ProductsEditPage />} />
        <Route path="/products" element={<ProductsHomePage />} />

        <Route path="/stocks/add" element={<AddStockRequestPage />} />
        <Route path="/stocks/:iid" element={<EditStockRequestPage />} />
        <Route path="/stocks" element={<StockRequestsPage />} />

        <Route path="/invoices/create" element={<AddInvoice2 />} />
        <Route path="/invoices/:iid" element={<EditInvoice />} />
        <Route path="/invoices" element={<InvoicesHomePage />} />

        <Route path="/rewards" element={<RewardsHomePage />} />
        <Route
          path="/settings/taxes"
          element={<OrganizationTaxesSettingsPage />}
        />
        <Route path="/settings" element={<OrganizationSettings />} />

        <Route
          path="/investments"
          element={<OrganizationInvestmentsHomePage />}
        />
        <Route path="/employees/:eid" element={<ViewEmployeePage />} />
        <Route path="/employees" element={<OrganizationEmployeesHomePage />} />
        <Route path="/expenses" element={<OrganizationExpensesHomePage />} />
      </Routes>
    </RequireOrgAdminAccess>
  );

  const requireSalesSettingsOrAdmin = (
    <RequireSalesSettingsOrAdmin>
      <Routes>
        <Route path="/:sid/returns" element={<ReturnsPage />} />
        <Route path="/retail" element={<CheckoutPage />} />
        <Route path="/restaurants/ff" element={<FastFoodPage />} />
        <Route path="/restaurants/tabs/:tid" element={<RestaurantTabPage />} />
        <Route path="/restaurants" element={<RestaurantsHomePage />} />
        <Route path="/" element={<OrganizationSalesHomePage />} />
      </Routes>
    </RequireSalesSettingsOrAdmin>
  );

  return (
    <>
      <LeftPaneComponent />
      <Routes>
        <Route path="/reimbursements" element={<ReimbursementsHomePage />} />
        <Route path="/sales/*" element={requireSalesSettingsOrAdmin} />
        <Route path="/payroll" element={<PayrollHomEmployeePage />} />
        <Route path="/timesheets" element={employeeRoutes} />
        <Route path="/" element={<OrganizationHomePage />} />
        <Route path="*" element={orgAdminRoutes} />
      </Routes>
    </>
  );
};
