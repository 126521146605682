import { Button, message, Popconfirm, Table, Typography } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDeleteStockRequest } from "../../../../api/api.delete";
import { useGetSupplyChainStockRequests } from "../../../../api/api.get";
import { toReadableDate } from "../../../../utils/date.utils";
import OrganizationDate from "../../organization-date";

const { Title } = Typography;

export default function StockRequestsPage() {
  const { id } = useParams();
  const [date, setDate] = useState(moment());
  const navigate = useNavigate();

  const { data: stockRequests, refetch } = useGetSupplyChainStockRequests(id);

  const { mutateAsync: deleteAsync } = useDeleteStockRequest(id!!);

  const handleDateChange = (dateString: string) => {
    setDate(moment(dateString, "MMM YYYY"));
  };

  useEffect(() => {
    if (date) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  const handleDelete = (row: any) => {
    deleteAsync({ stockReqId: row.id })
      .then(() => {
        message.success("Stock request successfully deleted.");
        refetch();
      })
      .catch(console.error);
  };

  const columns = [
    {
      title: "Vendor Name",
      dataIndex: "vendorName",
      key: "vendorName",
    },
    {
      title: "Vendor Phone",
      dataIndex: "vendorPhone",
      key: "vendorPhone",
    },
    {
      title: "Created",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (value: string) => <span>{toReadableDate(value)}</span>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "",
      key: "operation",
      width: 100,
      render: (row: any) => (
        <Button type="link" onClick={() => navigate(`${row.id}`)}>
          Edit
        </Button>
      ),
    },
    {
      title: "",
      key: "operation",
      width: 100,
      render: (row: any) => (
        <Popconfirm
          title="Delete"
          description="Are you sure you want to delete?"
          onConfirm={() => handleDelete(row)}
          okText="Yes"
          cancelText="No"
        >
          <Button danger type="text" color="danger">
            Delete
          </Button>
        </Popconfirm>
      ),
    },
  ];

  return (
    <div
      style={{ marginBottom: 100 }}
      className="card divColumn mx-40 my-40 flex-1"
    >
      <div className="divSpread mb-40">
        <OrganizationDate onChange={handleDateChange} />
        <Button type="link" onClick={() => navigate(`add`)}>
          Create
        </Button>
      </div>
      <div className="divSpread divAlignItemsOnly m-40">
        <Title level={3}>Stock Requests</Title>
      </div>
      <Title level={5}>Request inventory from your vendors.</Title>
      <Table dataSource={stockRequests} columns={columns} />
    </div>
  );
}
