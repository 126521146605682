import {
  BarcodeOutlined,
  DeleteFilled,
  DeleteOutlined,
  LoadingOutlined,
  StarFilled,
} from "@ant-design/icons";
import {
  AutoComplete,
  AutoCompleteProps,
  Avatar,
  Button,
  Card,
  Col,
  Divider,
  Flex,
  Input,
  List,
  Modal,
  Skeleton,
  Spin,
  Tag,
  Tooltip,
} from "antd";
import Meta from "antd/es/card/Meta";
import { TextAreaRef } from "antd/es/input/TextArea";
import Select, { DefaultOptionType } from "antd/es/select";
import moment from "moment";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useDebounce } from "use-debounce";
import { useCreateOrgSale } from "../../api/api";
import {
  useGetOrganizationById,
  useSearchCustomers,
  useSearchProducts,
  useSearchProductsByBarcode,
  useSearchRewardLevelByExternalId,
} from "../../api/api.get";
import ErrorPanel from "../../components/error.panel.component";
import { useNotifications2 } from "../../components/notifications/notification";
import { useCurrency } from "../../hooks/useCurrency";
import { IOrganizationRewardLevel, IProduct } from "../../types/types";
import { applyCoupons } from "../../utils/orders.utils";
const { Search } = Input;

export default function CheckoutPage() {
  const { id } = useParams();
  const [searchText, setSearchText] = useState<string>("");
  const [searchCouponText, setCouponSearchText] = useState<string>("");

  const [items, setItems] = useState<IProduct[]>([]);
  const [coupons, setCoupons] = useState<IOrganizationRewardLevel[]>([]);

  const [debouncedSearchText] = useDebounce(searchText, 500);
  const [debouncedSearchCouponText] = useDebounce(searchCouponText, 500);

  const { data: orgData } = useGetOrganizationById(id);
  const productTextInputRef = useRef<TextAreaRef>(null);
  const [nameSearch, setNameSearch] = useState<boolean>(false);
  const [options, setOptions] = useState<AutoCompleteProps["options"]>([]);
  const [servicesSearchText, setServicesSearchText] = useState<string>("");
  const [debouncedProductNameSearchText] = useDebounce(servicesSearchText, 300);
  const { areaCode } = useCurrency();
  const [customerPhoneText, setCustomerPhoneText] = useState<string>();
  const { notification, notifyError, notifySuccess } = useNotifications2();
  const [confirmCheckout, setConfirmCheckout] = useState<boolean>(false);
  const { mutateAsync, isPending } = useCreateOrgSale(id);
  const {
    data,
    isLoading: isSearchLoading,
    error,
  } = useSearchProductsByBarcode(id, debouncedSearchText);

  const [searchParams] = useSearchParams();

  const isDebug = searchParams.get("debug") === "true";

  const {
    data: couponData,
    isLoading: isSearchCouponLoading,
    error: couponError,
  } = useSearchRewardLevelByExternalId(id, debouncedSearchCouponText);

  const { data: productsByNameData } = useSearchProducts(
    id,
    debouncedProductNameSearchText,
  );
  const {
    data: customerSearchData,
    refetch: searchCustomer,
    error: customerNotFoundError,
  } = useSearchCustomers(areaCode!! + customerPhoneText, false);

  useEffect(() => {
    if (customerSearchData) {
      notifySuccess({ title: "Success", description: "Customer Found!" });
    }

    if (customerNotFoundError) {
      notifyError("Customer not found");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerNotFoundError, customerSearchData]);

  useEffect(() => {
    if (productTextInputRef.current) {
      setTimeout(() => {
        productTextInputRef.current?.focus();
      }, 500);
    }
  }, []);

  useEffect(() => {
    if (data) {
      const _items = [...items];
      _items.push(data);
      setItems(_items);
      setSearchText("");
      productTextInputRef.current?.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (couponData) {
      const _coupons = new Set(coupons);
      _coupons.add(couponData);
      setCoupons(Array.from(_coupons));
      setCouponSearchText("");
      // productTextInputRef.current?.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [couponData]);

  useEffect(() => {
    if (productsByNameData) {
      setOptions(
        productsByNameData.map((product: IProduct) => {
          return {
            value: product.title,
            title: product.title,
            id: product._id,
            price: product.price,
            cover: product.cover,
            salesTaxExcluded: product.salesTaxExcluded,
          };
        }),
      );
    }
  }, [productsByNameData]);

  const handleRemoveItems = (index: number) => {
    const _items = [...items];
    _items.splice(index, 1);
    setItems(_items);
  };

  const handleRemoveCoupons = (index: number) => {
    const _coupons = [...coupons];
    _coupons.splice(index, 1);
    setCoupons(_coupons);
  };

  const itemTotal: number = items.reduce(
    (acc, item) => acc + (item.price?.value ?? 0),
    0,
  );

  const {
    points: pointsTotal,
    discount: discountTotal,
    coupon: appliedCoupon,
  } = applyCoupons(itemTotal, coupons);

  /**
   * This is a super critical function
   *
   */
  const grandTotal = useMemo(() => {
    // items can have sales tax excluded
    const orgSalesTax = orgData?.salesTax ?? 0;

    const individualItemDiscount = discountTotal / items.length;

    return items.reduce((acc, item) => {
      const itemPrice = (item.price?.value ?? 0) - individualItemDiscount;

      if (item.salesTaxExcluded === true) {
        return acc + itemPrice;
      }
      return acc + itemPrice + (itemPrice * orgSalesTax) / 100;
    }, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemTotal]);

  const handleAddItems = useCallback(
    (option: DefaultOptionType) => {
      if (options) {
        const temp = [...items];
        temp.push({
          _id: option.id,
          title: option.title ?? "",
          price: option.price,
          createdAt: option.createdAt,
          cover: option.cover,
          salesTaxExcluded: option.salesTaxExcluded,
        });
        setItems(temp);

        setServicesSearchText("");
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [options],
  );

  const handleCheckout = (paymentType: string) => {
    setConfirmCheckout(true);
    mutateAsync({
      amount: itemTotal,
      saleDate: moment().format("YYYY-MM-DD"),
      category: items?.[0].title ?? "Scan Order - unknown",
      products: items.map((item) => item._id),
      tax: orgData?.salesTax ?? 0,
      phone: customerSearchData?.phone ?? customerPhoneText,
      paymentType,
      coupons: coupons.map((c) => +c.id),
      discountTotal: discountTotal,
    })
      .then(() => {
        notifySuccess({ title: "Success", description: "Order Created!" });
        setConfirmCheckout(false);
        setCustomerPhoneText("");
        setItems([]);
      })
      .catch(() => notifyError());
  };

  return (
    <div className="w-100 divFlex p-20" style={{ height: "100%" }}>
      {notification}

      <ConfirmCheckout
        total={grandTotal}
        open={confirmCheckout}
        onOK={(paymentType) => handleCheckout(paymentType!!)}
        onCancel={() => setConfirmCheckout(false)}
      />
      <div className="flex-1 p-0 divSpread divColumn">
        <div className="" style={{ overflowY: "scroll", height: 600 }}>
          {isSearchLoading && <Skeleton active />}

          {!isSearchLoading && (
            <List
              itemLayout="horizontal"
              bordered
              dataSource={items}
              renderItem={(item, index) => (
                <List.Item>
                  <List.Item.Meta
                    avatar={<Avatar src={item.cover} />}
                    title={<a href="https://ant.design">{item.title}</a>}
                    description={"$" + item.price?.value}
                  />

                  <Flex gap={20}>
                    {item.salesTaxExcluded === true && (
                      <Tooltip title="No sales tax">
                        <StarFilled style={{ color: "orange" }} />
                      </Tooltip>
                    )}
                    <DeleteOutlined
                      style={{ color: "red" }}
                      onClick={() => handleRemoveItems(index)}
                    />
                  </Flex>
                </List.Item>
              )}
            />
          )}
        </div>

        <div className="p-20 divColumn">
          <h4>Customer </h4>

          <Search
            addonBefore={areaCode!!}
            value={customerPhoneText}
            onSearch={() => searchCustomer()}
            style={{ width: "80%" }}
            onChange={(e) => setCustomerPhoneText(e.target.value)}
          />

          {customerSearchData && (
            <div className="mt-20">
              <Tag color="green">
                {customerSearchData?.firstName && customerSearchData?.lastName
                  ? `${customerSearchData?.firstName} ${customerSearchData?.lastName}`
                  : customerSearchData?.email}
              </Tag>
            </div>
          )}
        </div>
      </div>

      <Divider type="vertical" style={{ height: 600 }} />
      <div className="flex-1 divColumn divSpread p-20">
        <div>
          <div className="mb-20 divRight">
            <Button
              type="primary"
              onClick={() => setConfirmCheckout(true)}
              disabled={items.length === 0 || !customerPhoneText}
              loading={isPending}
            >
              Checkout
            </Button>
          </div>

          <Flex gap={5} className="divColumn">
            {!nameSearch && (
              <Flex vertical className="w-100">
                <Input.TextArea
                  ref={productTextInputRef}
                  disabled={isSearchLoading}
                  onChange={(e) => setSearchText(e.target.value)}
                  value={searchText}
                  placeholder="Place cursor here and scan.."
                />
                {error && (
                  <ErrorPanel
                    message={"Item not found with name " + searchText}
                  />
                )}
              </Flex>
            )}
            {nameSearch && (
              <AutoComplete
                size="large"
                options={options}
                value={servicesSearchText}
                onSelect={(_, option) => handleAddItems(option)}
                onSearch={(text) => setServicesSearchText(text)}
                placeholder="Search by name..."
                notFoundContent={"No results found."}
              />
            )}

            <Flex className="divRight">
              {nameSearch && (
                <Button type="link" onClick={() => setNameSearch(false)}>
                  <BarcodeOutlined />
                </Button>
              )}
              {!nameSearch && (
                <Button type="link" onClick={() => setNameSearch(true)}>
                  Search by name
                </Button>
              )}
            </Flex>
          </Flex>
        </div>

        <Divider />

        <div style={{ width: 600 }}>
          <div className="divRight">
            <Skeleton loading={isSearchCouponLoading}>
              <Input
                style={{ width: 200 }}
                onChange={(e) => setCouponSearchText(e.target.value)}
                value={searchCouponText}
                placeholder="Coupons.."
              />
            </Skeleton>
          </div>
          <div className="divRight my-5">
            {couponError && <ErrorPanel message={"Coupon not found."} />}
          </div>

          <div
            style={{
              overflowX: "auto",
              overflowY: "hidden",
              display: "flex",
              width: "100%",
            }}
          >
            {coupons.map((coupon: IOrganizationRewardLevel, index: number) => (
              <Col span={8}>
                <Card
                  className="p-20"
                  hoverable
                  title={<BarcodeOutlined />}
                  style={{
                    width: 200,
                    borderColor:
                      appliedCoupon?.id === coupon.id ? "limegreen" : undefined,
                  }}
                  extra={
                    <DeleteFilled
                      style={{
                        color: "var(--exhut-light-red)",
                        cursor: "pointer",
                      }}
                      onClick={() => handleRemoveCoupons(index)}
                    />
                  }
                >
                  <Meta title={coupon.name} description="www.instagram.com" />
                </Card>
              </Col>
            ))}
          </div>
        </div>

        <Divider />

        <div
          className="w-100 divColumn right-align"
          style={{ marginRight: 20, marginBottom: 0 }}
        >
          <span className="my-20" style={{ fontSize: 20 }}>
            Total : <strong>{itemTotal ?? 0}</strong>
          </span>

          <div className="divSpread">
            <span className="my-20" style={{ fontSize: 20 }}>
              Points : <strong>{pointsTotal ?? 0}</strong>
            </span>

            <span className="my-20" style={{ fontSize: 20 }}>
              Discount : <strong>{discountTotal ?? 0}</strong>
              {isDebug && <span>[{itemTotal - discountTotal}]</span>}
            </span>
          </div>

          <span className="my-20" style={{ fontSize: 20 }}>
            Sales Tax: <strong>{orgData?.salesTax ?? 0}%</strong>
            {isDebug && (
              <span>
                [
                {((orgData?.salesTax ?? 0) / 100) * (itemTotal - discountTotal)}
                ]
              </span>
            )}
          </span>
          <span style={{ fontSize: 65, fontWeight: "bold" }}>
            ${grandTotal.toFixed(2)}
          </span>
          {isSearchLoading && (
            <Spin indicator={<LoadingOutlined spin />} size="large" />
          )}
        </div>
      </div>
    </div>
  );
}

function ConfirmCheckout({
  open,
  total,
  onCancel,
  onOK,
}: {
  total: number;
  open: boolean;
  onOK: (paymentType?: string) => void;
  onCancel: () => void;
}) {
  const [paymentType, setPaymentType] = useState<string>();
  return (
    <div>
      <Modal
        title="Confirm Order?"
        open={open}
        onOk={() => onOK(paymentType)}
        onCancel={onCancel}
      >
        <div className="divColumn">
          <Flex className="divFlex divAlignItemsOnly my-20" gap={10}>
            <strong>Total</strong>
            <h1>${total.toFixed(2)}</h1>
          </Flex>

          <Flex vertical gap={10} className="my-40">
            <span>Select Payment Type</span>

            <Select
              id="first-time-select"
              defaultValue=""
              onChange={(value) => setPaymentType(value)}
              options={[
                { value: "cash", label: "Cash" },
                {
                  value: "card",
                  label: "Credit Card",
                },
              ]}
            />
          </Flex>
        </div>
      </Modal>
    </div>
  );
}
