import { Button, Flex, Modal, Table } from "antd";
import { useEffect, useMemo, useRef } from "react";
import { useReactToPrint } from "react-to-print";

import Alert from "antd/es/alert/Alert";
import moment from "moment";
import { useParams } from "react-router-dom";
import { useGetOrganizationById } from "../../../api/api.get";
import { useCurrency } from "../../../hooks/useCurrency";
import { IInvoice, IProductQuanityRowItem } from "../../../types/types";
import { capitalize, getCurrencySymbol } from "../../../utils/functions";

export default function ViewExpenseModal({
  open,
  onCancel,
  invoice,
  paymentsTotal,
  netTotal,
}: {
  netTotal?: number;
  paymentsTotal?: number;
  invoice?: IInvoice;
  open: boolean;
  onCancel: () => void;
}) {
  const { id } = useParams();
  const { currency, toAmount } = useCurrency();
  const { data: orgData } = useGetOrganizationById(id);

  const contentRef = useRef(null);
  const handlePrint = useReactToPrint({
    contentRef,
  });

  const columns = [
    {
      title: "Item",
      key: "title",
      render: (row: IProductQuanityRowItem) => (
        <span>{capitalize(row.product?.title)}</span>
      ),
    },
    {
      title: "Quantity",
      key: "quantity",
      render: (row: IProductQuanityRowItem) => <span>{row.quantity}</span>,
    },
    {
      title: "Price",
      render: (row: IProductQuanityRowItem) => (
        <span>
          {getCurrencySymbol(row.product?.price?.currency)}
          {row.product?.price?.value}
        </span>
      ),
    },
  ];
  const paymentColumns = [
    {
      title: "Date",
      dataIndex: "paymentDate",
      key: "paymentDate",
      render: (value: string) => <>{moment(value).format("DD MMM YYYY")}</>,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "paymentDate",
      render: (value: string) => (
        <>
          {currency}
          {value}
        </>
      ),
    },
    {
      title: "Type",
      dataIndex: "paymentType",
      key: "paymentType",
    },
    {
      title: "Customer Phone",
      dataIndex: "customerPhone",
      key: "customerPhone",
    },
  ];

  const paymentStatusLabel: "PAID" | "NOT PAID" | null = useMemo(() => {
    if (paymentsTotal && invoice) {
      if (paymentsTotal > 0) {
        if (paymentsTotal > invoice.total) {
          return "PAID";
        } else {
          return "NOT PAID";
        }
      }
      return "NOT PAID";
    }
    return "NOT PAID";
  }, [paymentsTotal, invoice]);

  useEffect(() => {});

  return (
    <Modal open={open} onOk={onCancel} onCancel={onCancel} footer={null}>
      <div className="divRight">
        <Button type="link" onClick={() => handlePrint()}>
          Print
        </Button>
      </div>

      <Flex gap={10} vertical style={{ padding: "40px 40px" }} ref={contentRef}>
        <div>
          <div className="divSpread divAlignItemsOnly">
            <h2>INVOICE</h2>
            <div className="small-font">#{invoice?.externalId}</div>
          </div>

          <div className="my-10">
            <div className="divColumn">
              <strong>{capitalize(orgData?.name)}</strong>
              <span>{orgData?.street1}</span>
              <span>{orgData?.street2}</span>
              <span>{orgData?.city}</span>
              <span>
                {orgData?.state} {orgData?.country} {orgData?.zipCode}
              </span>
            </div>
          </div>
        </div>

        <hr style={{ border: "1 solid black" }} />

        <div>
          <h4> BILL TO </h4>
          <div>
            <div className="my-10">
              <div className="divColumn">
                {(invoice?.customer?.firstName ||
                  invoice?.customer?.lastName) && (
                  <strong>
                    {capitalize(invoice?.customer?.firstName)}{" "}
                    {capitalize(invoice?.customer?.lastName)}
                  </strong>
                )}

                <span>{capitalize(invoice?.customer?.address?.street1)}</span>
                <span>{capitalize(invoice?.customer?.address?.street2)}</span>
                <span>{capitalize(invoice?.customer?.address?.city)}</span>
                <span>
                  {invoice?.customer?.address?.state && (
                    <span>
                      {capitalize(
                        invoice?.customer?.address?.state?.toUpperCase(),
                      )}
                      , {capitalize(invoice?.customer?.address?.countryCode)}
                    </span>
                  )}
                </span>
              </div>
            </div>
          </div>
          <div className="my-5">
            <div>
              <strong>Email</strong>
              <span className="mx-10">{invoice?.customer?.email}</span>
            </div>

            <div>
              <strong>Phone</strong>
              <span className="mx-10">{invoice?.customer?.phone}</span>
            </div>
          </div>
        </div>
        <hr style={{ border: "1 solid black" }} />

        <div className="my-0">
          <Table
            bordered
            dataSource={invoice?.items}
            columns={columns}
            pagination={{ hideOnSinglePage: true }}
          />
        </div>

        <Flex gap={20} vertical className="divRight my-20">
          <div className="divRight">
            <strong>Net Total - </strong>
            <span className="mx-10">{toAmount(netTotal)}</span>
          </div>
          <div className="divRight">
            <strong>Tax - </strong>
            <span className="mx-10">{invoice?.tax}%</span>
          </div>

          <div className="divRight divAlignItemsOnly">
            <h2 className="mx-10">{toAmount(invoice?.total)}</h2>
          </div>
        </Flex>
        <hr style={{ border: "1 solid black" }} />

        {invoice?.payments && invoice?.payments?.length > 0 && (
          <div className="my-0">
            <h4>Payments</h4>
            <Table
              bordered
              dataSource={invoice?.payments}
              columns={paymentColumns}
              pagination={{ hideOnSinglePage: true }}
            />
          </div>
        )}

        {invoice?.notes && (
          <div className="my-20">
            <strong className="mx-10">Notes:</strong>
            <div
              className="p-10"
              style={{ border: "1px solid black", borderRadius: 5 }}
            >
              {invoice?.notes}
            </div>
          </div>
        )}

        {paymentStatusLabel && (
          <div className="my-20 divCenterAlign">
            <Alert
              type={paymentStatusLabel === "PAID" ? "success" : "error"}
              message={paymentStatusLabel}
            />
          </div>
        )}
      </Flex>
    </Modal>
  );
}
